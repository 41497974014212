import React from 'react';
import { View, StyleSheet } from 'react-native';
import { observer, inject } from 'mobx-react';
import { AppStoreName, appStoreDefaultProps, AppStoreProps } from '../stores/AppStore';
import I18n from '../lib/i18n';
import Text from '../lib/components/Text';
import Button from '../lib/components/Button';
import InputField from '../lib/components/InputField';
import TypeUtil from '../lib/utils/TypeUtil';
import Transaction from '../models/Transaction';
import { Events } from '../lib/Events';
import TransactionStore from '../stores/TransactionStore';
import { primaryColor } from '../config/Color';
import Language from '../lib/i18n/Language';
import RadioForm, {RadioButton, RadioButtonInput, RadioButtonLabel} from 'react-native-simple-radio-button';
import DeviceInfo from '../lib/utils/DeviceInfo';

export interface Props extends AppStoreProps
{
	onDataChanged:() => void,
	isActive:boolean
}

@inject(AppStoreName) @observer
export default class PaymentStepProduct extends React.Component<Props>
{
	static defaultProps = appStoreDefaultProps;

	static products = [10, 20, 50, 100, 200, 500];

	inputField:InputField|undefined;

	static validateInput(transaction:Transaction):boolean
	{
		return transaction.amount >= 10 && transaction.amount <= 1000;
	}

	componentDidMount()
	{
		const transaction = this.props.appStore.transactionStore.transaction;
		if (transaction.amount > 0)
		{
			if (PaymentStepProduct.products.indexOf(transaction.amount) < 0)
			{
				if (this.inputField)
				{
					this.inputField.value = transaction.amount.toString();
					this.props.onDataChanged();
				}
			}
		}

		Events.listen(TransactionStore.EVENT_TRANSACTION_STATUS_CHANGED, this.onTransactionStatusChanged);
		Events.listen(Language.EVENT_LANGUAGE_CHANGED, this.onLanguageChanged);
		Events.listen(DeviceInfo.EVENT_SCREEN_CHANGED, this.onScreenChanged);
	}

	onScreenChanged = () =>
	{
		this.forceUpdate();
	}

	onLanguageChanged = () =>
	{
		this.forceUpdate();
	}

	componentWillUnmount()
	{
		Events.removeListener(DeviceInfo.EVENT_SCREEN_CHANGED, this.onScreenChanged);
		Events.removeListener(Language.EVENT_LANGUAGE_CHANGED, this.onLanguageChanged);
		Events.removeListener(TransactionStore.EVENT_TRANSACTION_STATUS_CHANGED, this.onTransactionStatusChanged);
	}

	onTransactionStatusChanged = () =>
	{
		this.forceUpdate();
	}

	onProductPress = (index:number) => () =>
	{
		this.inputField?.clear();

		let transaction = this.props.appStore.transactionStore.transaction;
		transaction.amount = PaymentStepProduct.products[index];

		this.props.onDataChanged();

		this.forceUpdate();
	}

	onInputChanged = (text:string) =>
	{
		let transaction = this.props.appStore.transactionStore.transaction;

		let nr = TypeUtil.stringToNumber(text);
		if (nr !== undefined)
		{
			transaction.amount = nr;
		}
		else
		{
			transaction.amount = 0;
		}

		this.props.onDataChanged();

		this.forceUpdate();
	}

	inputFieldEmpty():boolean
	{
		return this.inputField === undefined || this.inputField.isEmpty();
	}

	private onPurposeOptionPress = (index:number) => () =>
	{
		let transaction = this.props.appStore.transactionStore.transaction;
		transaction.purpose = index;
		this.forceUpdate();
	}

	render()
	{
		let transaction = this.props.appStore.transactionStore.transaction;
		let borderColor:string = transaction.amount === 0 ? primaryColor : "#EEE";
		const w = DeviceInfo.instance.width;

		return (
			<View
				style={styles.main}
			>
				<View style={styles.options}>
					{PaymentStepProduct.products.map((amount, index) =>
					{
						const isSelected = transaction.amount == amount;
						return (
							<Button
								key={index}
								onPress={this.onProductPress(index)}
								style={{
									backgroundColor: isSelected && this.inputFieldEmpty() ? "#111" : "white",
									borderColor,
									borderWidth: transaction.amount === 0 ? 2 : 0,
									marginHorizontal: 8,
									marginBottom: 16,
									minWidth: 80
								}}
							>
								<Text style={{
									color: isSelected && this.inputFieldEmpty() ? "#FFF" : primaryColor,
									fontWeight: "700",
									fontSize: 18
								}}>{amount.toString() + " EUR"}</Text>
							</Button>
						);
					})}
					
				</View>

				<View style={styles.selectAmount}>
					<InputField
						onInit={instance => this.inputField = instance}
						onChangeText={this.onInputChanged}
						labelText={I18n.t('freeamount')}
						labelTextSize={14}
						labelTextWeight="400"
						labelColor='#999'
						textColor='#111'
						borderBottomColor={borderColor}
						inputType='default'
						maxLength={6}
						defaultValue=""
						placeholder="EUR"
						inputStyle={{
							fontSize: 18,
							fontWeight: "700"
						}}
						numberOfLinesLabel={3}
					/>
				</View>

				<View style={{
					marginTop: 48
				}}>

					<Text style={{fontWeight: "600", fontSize: 16, marginBottom: 16}}>{I18n.t('purpose.title')}</Text>

					<RadioForm
						initial={0}
						animation={true}
					>
						{[
							{label: I18n.t('purpose.option0'), value: 0 },
							{label: I18n.t('purpose.option1'), value: 1 }
						].map((obj, i) => (
							<View key={i} style={{
								flexDirection: 'row',
								alignItems: 'center',
								flexWrap: 'nowrap'
							}}>
								<RadioButtonInput
									obj={obj}
									index={i}
									isSelected={transaction.purpose === i}
									onPress={this.onPurposeOptionPress(i)}
									borderWidth={1}
									buttonInnerColor={primaryColor}
									buttonOuterColor={primaryColor}
									buttonSize={20}
									buttonOuterSize={30}
									buttonStyle={{}}
									buttonWrapStyle={{marginLeft: 10}}
								/>
								<Button
									onPress={this.onPurposeOptionPress(i)}
									style={{marginLeft: 8}}
								>
									<Text style={{fontSize: 16, color: transaction.purpose === i ? primaryColor : '#555',
										fontFamily: transaction.purpose === i ? "OS700" : "OS400"}}>
										{w < 450 && i === 1 ? I18n.t('purpose.option1small') : I18n.t('purpose.option' + i)}
									</Text>
								</Button>
							</View>
						))} 
					</RadioForm>

					<Text style={{fontWeight: "300", fontSize: 13, marginTop: 32, color: '#999'}}>{I18n.t('purpose.disclaimer')}</Text>

				</View>

			</View>
		);
	}
}

const styles = StyleSheet.create({
	main: {
		flex: 1,
		flexDirection: 'column',
		alignItems: 'center'
	},
	options: {
		flexDirection: 'row',
		justifyContent: 'space-around',
		flexWrap: 'wrap'
	},
	selectAmount: {
		marginTop: 32,
		maxWidth: 260,
		width: '90%'
	},
	max1000: {
		marginTop: 64
	},
	max1000Text: {
		
	}
});