export default class Transaction
{
	public static STATUS_UNKNOWN:string = "unknown";
	public static STATUS_PENDING:string = "pending";
	public static STATUS_FAILED:string = "failed";
	public static STATUS_PROCESSED:string = "processed";
	public static STATUS_CANCELLED:string = "cancelled";
	public static STATUS_DELETED:string = "deleted";

	public static isCompletionStatus(status:string):boolean
	{
		if (status === Transaction.STATUS_PENDING)
			return false;
		else
			return true;
	}

	id:string|undefined;

	first_name:string|undefined;
	last_name:string|undefined;
	tipwin_id:string|undefined;
	email:string|undefined;
	amount:number = 0;
	purpose:number = 0;
	language:string|undefined;

	provider_id:string|undefined;
}