import React from 'react';
import { View, StyleSheet } from 'react-native';
import { observer, inject } from 'mobx-react';
import { AppStoreName, appStoreDefaultProps, AppStoreProps } from '../stores/AppStore';
import I18n from '../lib/i18n';
import InputField from '../lib/components/InputField';
import Transaction from '../models/Transaction';
import TipwinService from '../services/TipwinService';
import StringUtil from '../lib/utils/StringUtil';
import PaymentStepProduct from './PaymentStepProduct';
import { Events } from '../lib/Events';
import Language from '../lib/i18n/Language';
import DeviceInfo from '../lib/utils/DeviceInfo';

export interface Props extends AppStoreProps
{
	onDataChanged:() => void,
	isActive:boolean
}

@inject(AppStoreName) @observer
export default class PaymentStepUserInfo extends React.Component<Props>
{
	static defaultProps = appStoreDefaultProps;

	inputFirstName:InputField|undefined;
	inputLastName:InputField|undefined;
	inputEmail:InputField|undefined;
	inputTipwinUserId:InputField|undefined;

	static validateInput(transaction:Transaction):boolean
	{
		if (!PaymentStepProduct.validateInput(transaction))
			return false;
		
		if (!PaymentStepUserInfo.isFirstNameValid(transaction))
			return false;
		if (!PaymentStepUserInfo.isLastNameValid(transaction))
			return false;

		if (!transaction.email || !StringUtil.isValidEmail(transaction.email))
			return false;
		if (!transaction.tipwin_id || !TipwinService.isValidUserId(transaction.tipwin_id))
			return false;

		return true;
	}

	componentDidMount()
	{
		const transaction = this.props.appStore.transactionStore.transaction;

		let changed:boolean = false;
		if (transaction.first_name?.length)
		{
			if (this.inputFirstName)
			{
				this.inputFirstName.value = transaction.first_name;
				changed = true;
			}
		}
		if (transaction.last_name?.length)
		{
			if (this.inputLastName)
			{
				this.inputLastName.value = transaction.last_name;
				changed = true;
			}
		}
		if (transaction.email?.length)
		{
			if (this.inputEmail)
			{
				this.inputEmail.value = transaction.email;
				changed = true;
			}
		}
		if (transaction.tipwin_id?.length)
		{
			if (this.inputTipwinUserId)
			{
				this.inputTipwinUserId.value = transaction.tipwin_id;
				changed = true;
			}
		}

		if (changed)
			this.props.onDataChanged();

		Events.listen(Language.EVENT_LANGUAGE_CHANGED, this.onLanguageChanged);
		Events.listen(DeviceInfo.EVENT_SCREEN_CHANGED, this.onScreenChanged);
	}

	componentWillUnmount()
	{
		Events.removeListener(Language.EVENT_LANGUAGE_CHANGED, this.onLanguageChanged);
		Events.removeListener(DeviceInfo.EVENT_SCREEN_CHANGED, this.onScreenChanged);
	}

	onScreenChanged = () =>
	{
		this.forceUpdate();
	}

	onLanguageChanged = () =>
	{
		this.forceUpdate();
	}

	static isFirstNameValid(transaction:Transaction):boolean
	{
		return transaction.first_name !== undefined && transaction.first_name.length >= 3;
	}

	static isLastNameValid(transaction:Transaction):boolean
	{
		return transaction.last_name !== undefined && transaction.last_name.length >= 3;
	}

	onFirstNameChanged = (text:string) =>
	{
		let transaction = this.props.appStore.transactionStore.transaction;
		transaction.first_name = text;
		this.props.onDataChanged();
		this.forceUpdate();
	}

	onLastNameChanged = (text:string) =>
	{
		let transaction = this.props.appStore.transactionStore.transaction;
		transaction.last_name = text;
		this.props.onDataChanged();
		this.forceUpdate();
	}

	onEmailChanged = (text:string) =>
	{
		let transaction = this.props.appStore.transactionStore.transaction;
		transaction.email = text;
		this.props.onDataChanged();
		this.forceUpdate();
	}

	onTipwinUserIdChanged = (text:string) =>
	{
		let transaction = this.props.appStore.transactionStore.transaction;
		transaction.tipwin_id = text;
		this.props.onDataChanged();
		this.forceUpdate();
	}

	render()
	{
		let transaction = this.props.appStore.transactionStore.transaction;

		const inputFields:any[] = [
			{
				label: 'firstname',
				cb: this.onFirstNameChanged,
				instance: (instance) => this.inputFirstName = instance,
				currentValue: transaction.first_name,
				currentValueValid: PaymentStepUserInfo.isFirstNameValid(transaction)
			},
			{
				label: 'lastname',
				cb: this.onLastNameChanged,
				instance: (instance) => this.inputLastName = instance,
				currentValue: transaction.last_name,
				currentValueValid: PaymentStepUserInfo.isLastNameValid(transaction)
			},
			{
				label: 'email',
				cb: this.onEmailChanged,
				instance: (instance) => this.inputEmail = instance,
				inputType: 'email',
				currentValue: transaction.email,
				currentValueValid: StringUtil.isValidEmail(transaction.email || '')
			},
			{
				label: 'tipwinuserid',
				cb: this.onTipwinUserIdChanged,
				instance: (instance) => this.inputTipwinUserId = instance,
				maxLength: 10,
				currentValue: transaction.tipwin_id,
				currentValueValid: TipwinService.isValidUserId(transaction.tipwin_id || '')
			},
		];

		let showTipwinError:boolean = false;
		if (this.inputEmail && transaction.tipwin_id && transaction.tipwin_id.length > 0 && !TipwinService.isStartOfValidUserId(transaction.tipwin_id))
			showTipwinError = true;

		const w = DeviceInfo.instance.width;
		const smallVersion = w < 510;

		return (
			<View
				style={[styles.main, smallVersion ? {
					flexDirection: 'column',
					alignItems: 'center'
				} : {}]}
				pointerEvents={this.props.isActive ? undefined : "none"}
			>
				{inputFields.map((field, index) =>
				{
					return (
						<InputField
							key={index}
							onInit={field.instance}
							onChangeText={field.cb}
							labelText={I18n.t(field.label)}
							labelTextSize={14}
							labelTextWeight={field.currentValueValid ? '400' : '700'}
							labelColor={field.currentValueValid ? '#999' : '#111'}
							textColor='#111'
							borderBottomColor='#CCC'
							inputType={field.inputType || 'default'}
							defaultValue=""
							customStyle={[styles.inputWrapper, smallVersion ? {
									width: '100%'
								} : {}]}
							maxLength={field.maxLength || 32}
							inputStyle={{
								fontSize: 18,
								fontWeight: "700"
							}}
							errorLabel={field.label === 'tipwinuserid' && showTipwinError ? I18n.t("tipwinerror") : undefined}
							errorLabelStyle={{
								fontSize: 12,
								color: "#EE0000"
							}}
						/>
					);	
				})}
			</View>
		);
	}
}

const styles = StyleSheet.create({
	main: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		alignItems: 'flex-start',
		justifyContent: 'center'
	},
	inputWrapper: {
		marginBottom: 32,
		marginRight: 32,
		width: '40%'
	}
});