import { NativeModules } from 'react-native';
//import setCookie from 'set-cookie-parser';
import TypeUtil from '../utils/TypeUtil';
import LocalStorage from '../utils/LocalStorage';
import AsyncUtil from '../utils/AsyncUtil';
import Assert from '../debug/Assert';
import { Events } from '../Events';
import Constants from 'expo-constants';

export interface NetRequestMetrics
{
	numRequests:number,
	numBytes:number,
	neededMs:number,
	numErrors:number
}

export default class NetRequest
{
	// There is a bug with fetch(...) on older Android devices so we have to process cookies ourselves
	private static cookies = new Map<string, string>();

	public static readonly EVENT_REQUEST_FAILED:string = "netrequest.failed";

	private static _metrics = {
		numRequests: 0,
		numBytes: 0,
		neededMs: 0.0,
		numErrors: 0
	};

	private static dummy:number = 0;

	public static async init():Promise<void>
	{
		if (!(Constants.platform && Constants.platform.web))
		{
			const Networking = NativeModules.Networking;
			var clearResolve;
			let clearPromise = new Promise(resolve => { clearResolve = resolve;});
			Networking.clearCookies((cleared) => {
				if (clearResolve)
					clearResolve();
			});
			await clearPromise;

			await NetRequest.loadCookies();
		}
	}

	public static async post(uri:string, data:any = undefined, stringify:boolean = true, onProgress:((this: XMLHttpRequest, ev: ProgressEvent) => any) | undefined = undefined,
		additionalHeaders:any = undefined):Promise<any|undefined>
	{
		return NetRequest.fetch(uri, data, stringify, onProgress, "POST", undefined, additionalHeaders);
	}

	public static async delete(uri:string, data:any = undefined, stringify:boolean = true, onProgress:((this: XMLHttpRequest, ev: ProgressEvent) => any) | undefined = undefined,
		additionalHeaders:any = undefined):Promise<any|undefined>
	{
		const uriWithParams:string = NetRequest.addParamsToUri(uri, data);
		return NetRequest.fetch(uriWithParams, undefined, stringify, onProgress, "DELETE", undefined, additionalHeaders);
	}

	public static async get(uri:string, data:any = undefined, stringify:boolean = true, onProgress:((this: XMLHttpRequest, ev: ProgressEvent) => any) | undefined = undefined,
		additionalHeaders:any = undefined):Promise<any|undefined>
	{
		const uriWithParams:string = NetRequest.addParamsToUri(uri, data);
		return NetRequest.fetch(uriWithParams, undefined, stringify, onProgress, "GET", undefined, additionalHeaders);
	}

	public static async getText(uri:string, data:any = undefined, stringify:boolean = true, onProgress:((this: XMLHttpRequest, ev: ProgressEvent) => any) | undefined = undefined,
		additionalHeaders:any = undefined):Promise<string|number>
	{
		const uriWithParams:string = NetRequest.addParamsToUri(uri, data);
		return NetRequest.fetchText(uriWithParams, undefined, stringify, onProgress, "GET", undefined, undefined, undefined, additionalHeaders);
	}

	private static addParamsToUri(uri:string, data:any):string
	{
		let uriWithParams:string = uri;
		if (data)
		{
			let first:boolean = true;
			if (uri.indexOf("?") >= 0)
				first = false;

			for (let k in data)
			{
				uriWithParams += first ? "?" : "&";
				uriWithParams += encodeURIComponent(k) + "=" + encodeURIComponent(data[k])
				first = false;
			}
		}

		return uriWithParams;
	}

	public static async put(uri:string, data:any = undefined, stringify:boolean = true, onProgress:((this: XMLHttpRequest, ev: ProgressEvent) => any) | undefined = undefined):Promise<any|undefined>
	{
		return NetRequest.fetch(uri, data, stringify, onProgress, "PUT");
	}

	public static async fetch(uri:string, data:any = undefined, stringify:boolean = true, onProgress:((this: XMLHttpRequest, ev: ProgressEvent) => any) | undefined = undefined,
		method:string = "POST", authToken:string|undefined = undefined, additionalHeaders:any = undefined):Promise<any|undefined>
	{
		let responseText:string|number = await NetRequest.fetchText(uri, data, stringify, onProgress, method, undefined, undefined, authToken, additionalHeaders);

		if (TypeUtil.isNumber(responseText))
			return undefined;

		if (TypeUtil.isJsonString(responseText))
			return JSON.parse(responseText);
		else
			return undefined;
	}

	// Returns the result string on HTTP code 200, otherwise returns the HTTP status codes as number
	public static async fetchText(url:string, data:any = undefined, stringify:boolean = true, onProgress:((this: XMLHttpRequest, ev: ProgressEvent) => any) | undefined = undefined, method:string = "POST",
		responseHeaders:any = undefined, asFormData:boolean = false, authToken:string|undefined = undefined, additionalHeaders:any = undefined):Promise<string|number>
	{
		const start = Date.now();
		const cookieStr = NetRequest.createCookieStr(NetRequest.cookies);

		let headers:any = {
			'Accept': '*/*',
			'Cookie': cookieStr,
		};

		if (stringify)
		{
			headers['Content-Type'] = 'application/json';
		}
		else if (asFormData)
		{
			headers['Content-Type'] = 'multipart/form-data';
		}

		if (authToken)
		{
			headers['Authorization'] = "Bearer " + authToken;
		}

		let referrer:any;
		if (additionalHeaders)
		{
			for (const name in additionalHeaders)
			{
				const _name = name.toLowerCase();
				if (name === "referrer" || _name === "referrer")
					referrer = additionalHeaders[name];
				else
					headers[name] = additionalHeaders[name];
			}
		}

		let onProgressBody:any;
		if (onProgress)
		{
			onProgressBody = stringify ? JSON.stringify(data) : data;
			if (method === "GET" || method === "HEAD" || method === "DELETE")
				onProgressBody = undefined;
		}

		//const retryWaitMs = [0, 100, 300, 1000, 2000, 3000];
		//const retryWaitMs = [0, 500, 2000];
		const retryWaitMs = [0];
		for (let i = 0; i < retryWaitMs.length; ++i)
		{
			const waitMs = retryWaitMs[i];
			if (waitMs)
				await AsyncUtil.sleepAsync(waitMs);

			let headerObject = new Headers(headers);
			let request = new Request(url,
			{
				method: method,
				// if fetchWithProgress is used, don't process/read the data, otherwise it will be processed twice which does not work with file-uploads and FormData
				body: onProgress ? "" : (stringify ? JSON.stringify(data) : data),
				//mode: (Constants.platform && Constants.platform.web) ? 'no-cors' : 'cors',
				mode: 'cors', // no-cors does not allow content-type/json
				//credentials: 'include', // needed for session IDs
				//redirect: 'follow', // "manual" does not work in React Native
				headers: headerObject,
				referrer: referrer
			});

			try
			{
				//console.log("REQ: " + method + ": " + url);
				//console.log("  DATA: ", data);

				if (onProgress)
				{
					let resultString:string = await NetRequest.fetchWithProgress(url, onProgressBody, request, onProgress, responseHeaders);
					//console.log(resultString);
					Assert.isValid(resultString);
					const neededMs = Math.round(Date.now() - start);
					NetRequest.onHttp200(url, neededMs, resultString ? resultString.length : 0);
					return resultString || "";
				}
				else
				{
					let res:Response;
					try
					{
						res = await fetch(request);
					}
					catch (e)
					{
						Events.fire(NetRequest.EVENT_REQUEST_FAILED, 0);
						return 0;
					}
					Assert.isValid(res);
					if (res && responseHeaders)
						NetRequest.parseResponseHeaders(res.headers, responseHeaders);

					//console.log(res.status);
					//console.log(res.headers);

					//NetRequest.OnFetchResponse(res) // process returned cookies

					if (res.status >= 200 && res.status < 300)
					{
						const result:string = await res.text();
						//console.log(result);

						const neededMs = Math.round(Date.now() - start);
						NetRequest.onHttp200(url, neededMs, result ? result.length : 0);

						return result || "";
					}
					else
					{
						// console.log("REQ: " + method + ": " + url);
						//  console.log(" status = " + res.status);
						//  const result:string = await res.text();
						//  console.log(result);

						Events.fire(NetRequest.EVENT_REQUEST_FAILED, res.status);
						return res.status;
					}
				}
			}
			catch (e)
			{
				// network request failed => retry, or exit
				if (i >= retryWaitMs.length - 1)
					throw e;
			}
		}

		return 400;
	}

	private static parseResponseHeaders(headers:Headers, result:any):void
	{
		if (headers)
		{
			if (headers.forEach)
			{
				headers.forEach((value:string, name:string) =>
				{
					result[name.toLowerCase()] = value;
				});
			}
			else
			{
				for (const name in headers)
				{
					result[name.toLowerCase()] = headers[name];
				}
			}
		}
	}

	public static get metrics():NetRequestMetrics
	{
		return NetRequest._metrics;
	}

	private static createHttpRequest(method:string, url:string):XMLHttpRequest
	{
		let xhr = new XMLHttpRequest();
		xhr.open(method, url, true);
		return xhr;
	}

	private static fetchWithProgress(url:string, data:any, opts:Request, onProgress:((this: XMLHttpRequest, ev: ProgressEvent) => any) | undefined,
		responseHeaders:any = undefined):Promise<string>
	{
		return new Promise( (res, rej)=>
		{
			let xhr = NetRequest.createHttpRequest(opts.method || 'GET', url);
			xhr.withCredentials = true;
			if (opts.headers)
			{
				opts.headers.forEach(function(hValue, hKey)
				{
					if (hKey !== "content-type")
						xhr.setRequestHeader(hKey, hValue);
				});
			}
			xhr.onload = (e:any) =>
			{
				if (e.target.responseHeaders && responseHeaders)
				{
					NetRequest.parseResponseHeaders(e.target.responseHeaders, responseHeaders);
				}
				res(e.target.responseText);
			};
			xhr.onreadystatechange = function()
			{
				if (xhr.status !== 200)
				{
					rej(xhr.status);
				}
	   		}
			xhr.onerror = rej;
			if (xhr.upload && onProgress)
				xhr.upload.onprogress = onProgress;
			xhr.send(data);
		});
	}

	private static async loadCookies():Promise<void>
	{
		let cs = await LocalStorage.get("cookies", "");
		if (cs && cs.length > 0)
		{
			let arr = cs.split(";");
			for (let i = 0; i < arr.length; ++i)
			{
				let kv = arr[i].split("=");
				if (kv.length !== 2)
					continue;

				if (kv[0] && kv[0] !== "undefined")
				{
					NetRequest.cookies[kv[0]] = kv[1];
				}
			}
		}
	}

	private static createCookieStr(cookies:Map<string, string>):string
	{
		let result = "";
		for (let name in cookies)
		{
			if (!name)
				continue;
			const value = cookies[name];
			if (value === undefined || value.length === 0)
				continue;

			let str = name + "=" + value;
			if (result.length > 0)
				result += ";";
			result += str;
		}

		return result;
	}

	// static async OnFetchResponse(response)
	// {
	// 	let setcookieLine = response.headers.get('x-sc');
	// 	if (setcookieLine)
	// 	{
	// 		//console.log("OnFetchResponse: " + setcookieLine);
	// 		let cookieHeader = setCookie.splitCookiesString(setcookieLine);
	// 		let newCookies = setCookie.parse(cookieHeader);
	// 		if (newCookies && newCookies.length > 0)
	// 		{
	// 			for (let i = 0; i < newCookies.length; ++i)
	// 			{
	// 				if (newCookies[i].name)
	// 					RestApi.cookies[newCookies[i].name] = newCookies[i].value;
	// 			}
	// 		}

	// 		let cs = RestApi.CreateCookieStr(RestApi.cookies);
	// 		await Utils.SetLocalStorage("cookies", cs);
	// 	}
	// }

	private static onHttp200(url:string, neededMs:number, numBytes:number):void
	{
		NetRequest._metrics["" + NetRequest._metrics.numRequests + "." + url] = neededMs + "-" + numBytes;
		NetRequest._metrics.numRequests++;
		NetRequest._metrics.neededMs += neededMs;
		NetRequest._metrics.numBytes += numBytes;
	}
}
