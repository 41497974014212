const apiHostProduction = 'https://konto.e-albers.de';
//const apiHostDevelopment = 'http://localhost:5000';
const apiHostDevelopment = 'https://konto.e-albers.de';

export const siteEnabled = false;

export function getApiHost():string
{
	if (__DEV__)
		return apiHostDevelopment;
	else
		return apiHostProduction;
}