export default class TrackingEvent
{
	public eventName:string = "";
	public deviceType?:string;
	public deviceName?:string;
	public screenWidth?:number;
	public screenHeight?:number;
	public os?:string;
	public osVersion?:string|number;
	public clientId?:string;
	public userId?:string;
	public sessionId?:string;
	public buildVersion?:string;
	public userFirstVisitDate?:number; // unix timestamp
	public userLastVisitDate?:number; // unix timestamp
	public userVisitCount?:number;
}