export default abstract class MathUtil
{
	public static toDegrees(angle:number):number
	{
		return angle * 180.0 / Math.PI;
	}

	public static toRadians(angle:number):number
	{
		return angle * Math.PI / 180.0;
	}

	public static normalizeRadians(rad:number):number
	{
		while (rad > 2 * Math.PI)
			rad -= 2 * Math.PI;
		while (rad < 0)
			rad += 2 * Math.PI;
		return rad;
	}

	public static diffInRadians(angle0:number, angle1:number):number
	{
		const a0 = MathUtil.normalizeRadians(angle0);
		const a1 = MathUtil.normalizeRadians(angle1);
		return a0 - a1;
	}

	public static interpolate(a:number, b:number, t:number, clamp:boolean):number
	{
		if (clamp)
			t = Math.min(1, Math.max(0, t));
			
		return a * (1 - t) + b * t;
	}

	public static interpolateAngle(start:number, end:number, t:number):number
	{
		const difference = Math.abs(end - start);
        if (difference > Math.PI)
        {
            // We need to add on to one of the values.
            if (end > start)
            {
                // We'll add it on to start...
                start += Math.PI * 2;
            }
            else
            {
                // Add it on to end.
                end += Math.PI * 2;
            }
        }

        // Interpolate it.
        const value = (start + ((end - start) * t));

        // Wrap it..
        const rangeZero = Math.PI * 2;

        if (value >= 0 && value <= Math.PI * 2)
            return value;

        return (value % rangeZero);
	}

	public static round(n:number, decimals:number):number
	{
		const p = Math.pow(10, decimals);
		return Math.round(n * p) / p;
	}

	/*
	 * Returns a random integer between min (inclusive) and max (inclusive).
	 * The value is no lower than min (or the next integer greater than min
	 * if min isn't an integer) and no greater than max (or the next integer
	 * lower than max if max isn't an integer).
	 * Using Math.round() will give you a non-uniform distribution!
	 */
	public static randomInt(min:number, max:number):number
	{
		const _min = Math.ceil(min);
		const _max = Math.floor(max);
		return Math.floor(Math.random() * (_max - _min + 1)) + _min;
	}
}