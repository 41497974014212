import StringUtil from "../lib/utils/StringUtil";

export default class TipwinService
{
	public static isValidUserId(_id:string):boolean
	{
		if (!_id || _id.trim().length === 0)
			return false;

		let id = _id.trim();

		if (id.length > 10 || id.length < 8)
			return false;

		if (id.startsWith("A") && id.length == 8 && StringUtil.onlyDigits(id.substring(1)))
			return true;
		if (id.startsWith("AB") && id.length == 9 && StringUtil.onlyDigits(id.substring(2)))
			return true;
		if (id.startsWith("ALB") && id.length == 10 && StringUtil.onlyDigits(id.substring(3)))
			return true;
		if (id.startsWith("C") && id.length == 8 && StringUtil.onlyDigits(id.substring(1)))
			return true;
		if (id.startsWith("N") && id.length == 8 && StringUtil.onlyDigits(id.substring(1)))
			return true;

		return false;
	}

	public static isStartOfValidUserId(_id:string):boolean
	{
		if (TipwinService.isValidUserId(_id))
			return true;

		if (!_id)
			return true;

		let id = _id.trim();

		const len = id.length;
		if (len === 0)
			return true;

		if (len === 1)
		 	return TipwinService.isFirstLetterValid(id);

		if (len === 2)
		{
			if (id == "AB" || id == "AL")
				return true;
			if (TipwinService.isFirstLetterValid(id))
				return StringUtil.onlyDigits(id.substring(1));
			else
				return false;
		}

		if (id.startsWith("ALB"))
			return TipwinService.isValidUserId(StringUtil.fillUpToLength(id, 10, "1"));
		if (id.startsWith("AB"))
			return TipwinService.isValidUserId(StringUtil.fillUpToLength(id, 9, "1"));
		if (id.startsWith("A") || id.startsWith("C") || id.startsWith("N"))
			return TipwinService.isValidUserId(StringUtil.fillUpToLength(id, 8, "1"));

		return false;
	}

	private static isFirstLetterValid(id:string):boolean
	{
		let c = id.substring(0, 1);
		return (c === "A" || c === "C" || c === "N");
	}
}