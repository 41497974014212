import { Logger } from "../lib/debug/Logger";
import * as Font from "expo-font";
import { observable, action, runInAction } from "mobx";
import Tracking from "../lib/tracking/Tracking";
import Language from "../lib/i18n/Language";
import DeviceInfo from "../lib/utils/DeviceInfo";
import NetRequest from "../lib/network/NetRequest";
import { getApiHost } from "../config/Backend";

const { log, error } = Logger.create({ channel: "loading", enabled: true, color: "#555588" });

export default class AppLoadingStore
{
	@observable isAppLoading:boolean = true;
	private onLoadingDone:() => Promise<void>;

	constructor(onLoadingDone:() => Promise<void>)
	{
		this.onLoadingDone = onLoadingDone;
	}

	@action
	async initSystems(additionalLoadingTask:(() => void)|undefined, resolve: (value?: void | PromiseLike<void> | undefined) => void):Promise<void>
	{
		try
		{
			await DeviceInfo.instance.initAsync();

			await NetRequest.init();

			log("initsystems.start");

			await Tracking.init(getApiHost());

			await Language.init();

			if (additionalLoadingTask)
			{
				await additionalLoadingTask();
			}

			await this.onLoadingDone();

			log("initsystems.end");
		}
		catch (err)
		{
			Tracking.onError(err);
		}

		if (resolve)
			resolve();
	}

	@action
	public onLoadingError = (err:any) =>
	{
		try
		{
			Tracking.onError(err);
		}
		catch (e)
		{}
	}

	@action
	public onLoadingFinished = () =>
	{
		//console.log("---loading finished");
		this.isAppLoading = false;
	}

	public startLoading = (additionalLoadingTask:(() => void)|undefined) => async ():Promise<void> =>
	{
		let initSystemPromise:Promise<void> = new Promise<void>(async (resolve, reject):Promise<void> =>
		{
		 	await this.initSystems(additionalLoadingTask, resolve);
		});

		let loadFonts:Promise<void> = Font.loadAsync({
			'OS300': require('../../assets/fonts/OpenSans-Light.ttf'),
			'OS400': require('../../assets/fonts/OpenSans-Regular.ttf'),
			'OS600': require('../../assets/fonts/OpenSans-SemiBold.ttf'),
			'OS700': require('../../assets/fonts/OpenSans-Bold.ttf'),
		});

		return Promise.all<void>([
			loadFonts,
			initSystemPromise,
		]) as any;
	}
}