import React from "react";
import { ActivityIndicator } from "react-native";

export interface Props
{
	color:string,
	size:number | "small" | "large"
}

export default class Spinner extends React.PureComponent<Props>
{
	static defaultProps = {
		color: "#555",
		size: "large"
	};

	render()
	{
		return (
			<ActivityIndicator
				{...this.props}
				color={this.props.color}
				size={this.props.size}
			/>
		);
	}
}