import React from 'react';
import { observer, inject } from 'mobx-react';
import { View, StyleSheet } from 'react-native';
import AppStore, { AppStoreName, AppStoreProps } from '../stores/AppStore';
import Language from '../lib/i18n/Language';
import I18n from '../lib/i18n';
import { Events } from '../lib/Events';
import DeviceInfo from '../lib/utils/DeviceInfo';
import { CheckoutResult } from '../stores/TransactionStore';
import Text from '../lib/components/Text';
import Spinner from '../lib/components/Spinner';

export interface Props extends AppStoreProps
{
	currency:string,
	interactive:boolean,
	error:boolean
}

@inject(AppStoreName) @observer
export default class Paypal extends React.Component<Props>
{
	static defaultProps = {
		appStore: (null as unknown) as AppStore,
		navigation: undefined,
		currency: "EUR",
		interactive: true,
		error: false
	};

	private isInProgress:boolean = false;

	getDocument():any
	{
		return document;
	}

	getWindow():any
	{
		return window;
	}

	getPaypal():any
	{
		return paypal;
	}

	async componentDidMount()
	{
		this.getPaypal().Buttons({
			enableStandardCardFields: true,
			style: {
				layout:  'vertical',
				color:   'blue',
				shape:   'rect',
				label:   'paypal'
			},
			createOrder: async (data, actions) =>
			{
				return this.startCheckout()
					.then(function (checkoutResult)
					{
						return checkoutResult.pid;
					})
					.catch((reason) =>
					{
						console.error(reason);
						this.onCheckoutError();
					});
			},
			onApprove: async (data, actions) =>
			{
				return this.captureCheckout(data.orderID, data.payerID)
					.then(function (checkoutResult)
					{
					})
					.catch((reason) =>
					{
						console.error(reason);
						this.onCheckoutError();
					});
			},
			onError: (err) =>
			{
				console.error(err);
				this.onCheckoutError();
			},
			onCancel: (data) =>
			{
				this.onCancelCheckout();
			},
		}).render('#paypal-button-container');
	}

	onCheckoutError()
	{
		this.props.appStore.transactionStore.onPaymentStepCanceled(false);
	}

	onCancelCheckout()
	{
		this.props.appStore.transactionStore.onPaymentStepCanceled(false);
	}

	async startCheckout():Promise<CheckoutResult>
	{
		this.setProgress(true);
		let checkoutResult:CheckoutResult|undefined = undefined;
		try
		{
			checkoutResult = await this.props.appStore.transactionStore.paypalCheckout();
			return checkoutResult;
		}
		finally
		{
			this.setProgress(false);
		}
	}

	async captureCheckout(orderId:string, payerId:string):Promise<CheckoutResult>
	{
		this.setProgress(true);
		let checkoutResult:CheckoutResult|undefined = undefined;
		try
		{
			checkoutResult = await this.props.appStore.transactionStore.paypalCaptureCheckout(orderId, payerId);
			return checkoutResult;
		}
		finally
		{
			this.setProgress(false);
		}
	}

	setProgress(flag:boolean)
	{
		this.isInProgress = flag;
		this.forceUpdate();
	}

	render()
	{
		let width:number = 350;
		if (DeviceInfo.instance.width < 800)
		{
			const padding = 24;
			width = Math.round(DeviceInfo.instance.width - 2 * padding);
		}

		var htmlCode:string = "<div id=\"paypal-button-container\"></div>";
			
		return (
			<View style={{
				width: width
			}}>
				{this.props.interactive &&
					<Text style={{fontWeight: "700", fontSize: 20, marginBottom: 32}}>{I18n.t("paymentheader")}</Text>}
				{this.props.interactive &&
					<div  dangerouslySetInnerHTML={{ __html: htmlCode }} />}

				{this.isInProgress &&
					<View style={{
						position: 'absolute',
						top: 0, left: 0, bottom: 0, right: 0,
						backgroundColor: 'rgba(255,255,255,0.75)',
						justifyContent: 'center',
						alignItems: 'center',
						zIndex: 999999
					}}>
						<Spinner />
					</View>}
			</View>
		);
	}
}


const styles = StyleSheet.create({
	main: {
	},
});