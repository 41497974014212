import React from 'react';
import { View, StyleSheet } from 'react-native';
import { observer, inject } from 'mobx-react';
import { AppStoreName, appStoreDefaultProps, AppStoreProps } from '../stores/AppStore';
import PaymentStepUserInfo from './PaymentStepUserInfo';
import PaymentStepProduct from './PaymentStepProduct';
import I18n from '../lib/i18n';
import PaymentStepSkrill from './PaymentStepSkrill';
import Button from '../lib/components/Button';
import Text from '../lib/components/Text';
import { Events } from '../lib/Events';
import TransactionStore from '../stores/TransactionStore';
import PaymentStepsHeader from './PaymentStepsHeader';
import { primaryColor } from '../config/Color';
import Language from '../lib/i18n/Language';
import DeviceInfo from '../lib/utils/DeviceInfo';
import PaymentStepBraintree from './PaymentStepBraintree';
import Transaction from '../models/Transaction';
import PaymentStepPaypal from './PaymentStepPaypal';
import { siteEnabled } from '../config/Backend';

export interface Props extends AppStoreProps
{
	provider:"paypal"|"skrill"|"braintree"
}

@inject(AppStoreName) @observer
export default class PaymentSteps extends React.Component<Props>
{
	static defaultProps = appStoreDefaultProps;

	//swiperRef:Swiper|undefined;
	
	static NUM_STEPS:number = 3;
	currentStep:number = 0;
	nextEnabled:boolean = false;
	prevEnabled:boolean = false;

	componentDidMount()
	{
		Events.listen(TransactionStore.EVENT_TRANSACTION_STATUS_CHANGED, this.onTransactionStatusChanged);
		Events.listen(Language.EVENT_LANGUAGE_CHANGED, () => { this.forceUpdate(); });
		Events.listen(DeviceInfo.EVENT_SCREEN_CHANGED, () => { this.forceUpdate(); });
	}

	componentWillUnmount()
	{
		Events.removeListener(TransactionStore.EVENT_TRANSACTION_STATUS_CHANGED, this.onTransactionStatusChanged);
	}

	onBack = () =>
	{
		if (this.prevEnabled && this.currentStep > 0)
		{
			//this.swiperRef?.goToPrev();
			this.currentStep--;
			if (this.currentStep > 0)
				this.prevEnabled = true;
			else
				this.prevEnabled = false;

			if (this.currentStep === 0)
				this.nextEnabled = PaymentStepProduct.validateInput(this.props.appStore.transactionStore.transaction);
			else if (this.currentStep === 1)
			{
				this.nextEnabled = PaymentStepUserInfo.validateInput(this.props.appStore.transactionStore.transaction);
				this.props.appStore.transactionStore.onPaymentStepCanceled();
			}

			this.forceUpdate();
		}
	}

	onNext = () =>
	{
		if (this.nextEnabled && this.currentStep < PaymentSteps.NUM_STEPS - 1)
		{
			//this.swiperRef?.goToNext();
			this.currentStep++;
			this.nextEnabled = false;

			if (this.currentStep === 1)
			{
				this.nextEnabled = PaymentStepUserInfo.validateInput(this.props.appStore.transactionStore.transaction);
				this.prevEnabled = true;
			}
			else if (this.currentStep === 2)
			{
				this.onCheckoutStarted();
			}

			this.forceUpdate();

			// Scroll to top on small screens
			if (DeviceInfo.instance.width < 600)
			{
				setTimeout(() => {
					window.scrollTo({ top: 160, behavior: 'smooth' });
				}, 200);
			}
		}
	}

	onProductChanged = () =>
	{
		let previousValue = this.nextEnabled;
		this.nextEnabled = PaymentStepProduct.validateInput(this.props.appStore.transactionStore.transaction);
		if (this.nextEnabled != previousValue)
			this.forceUpdate();
	}

	onUserInfoChanged = () =>
	{
		let previousValue = this.nextEnabled;
		this.nextEnabled = PaymentStepUserInfo.validateInput(this.props.appStore.transactionStore.transaction);
		if (this.nextEnabled != previousValue)
			this.forceUpdate();
	}

	async onCheckoutStarted()
	{
		this.prevEnabled = false;
		this.forceUpdate();

		if (this.props.provider == "skrill")
			await this.props.appStore.transactionStore.prepareSkrillPayment();
		else if (this.props.provider == 'braintree')
			await this.props.appStore.transactionStore.prepareBraintreePayment();
		else if (this.props.provider == 'paypal')
			await this.props.appStore.transactionStore.preparePaypalPayment();
		else
			this.props.appStore.transactionStore.preparePaymentBase();
			
		this.prevEnabled = true;
		this.forceUpdate();
	}

	onRestart = () =>
	{
		//this.swiperRef?.goTo(0);

		this.currentStep = 0;
		this.nextEnabled = false;
		this.prevEnabled = false;

		this.props.appStore.transactionStore.onRestart();
		this.forceUpdate();
	}

	onTransactionStatusChanged = () =>
	{
		this.forceUpdate();
	}

	render()
	{
		const transaction = this.props.appStore.transactionStore.transaction;
		const isProcessed = transaction.provider_id && this.props.appStore.transactionStore.transactionStatus === Transaction.STATUS_PROCESSED;
		const isProcessingError = transaction.provider_id && this.props.appStore.transactionStore.transactionStatus === Transaction.STATUS_FAILED;
		const isCanceled = transaction.provider_id && this.props.appStore.transactionStore.transactionStatus === Transaction.STATUS_CANCELLED;
		const showRestart = isProcessed || isProcessingError || isCanceled;

		let minHeight:number = 440;
		let minHeightStep2:number = 840;

		const step3SmallVersion = DeviceInfo.instance.width < 800;
		if (step3SmallVersion)
			minHeightStep2 = 1000;

		// const w = DeviceInfo.instance.width;
		// if (w < 260)
		// 	minHeight = 520;
		// else if (w < 360)
		// 	minHeight = 480;

		return (
			<View
				style={styles.main}
			>
				<View style={{
					minHeight : this.currentStep === 2 ? minHeightStep2 : minHeight
				}}>

					{siteEnabled && 
						<PaymentStepsHeader currentIndex={this.currentStep} count={3}
							currentLabel={I18n.t("stepTitle" + this.currentStep.toString())}
							allDone={this.props.appStore.transactionStore.transactionStatus === Transaction.STATUS_PROCESSED}
						/>}

					<View
						style={{
							flex: 1,
							marginTop: 80
						}}
					>
						{!siteEnabled &&
							<View style={{
								flexDirection: 'row',
								justifyContent: 'center',
								alignItems: 'center',
							}}>
								<Text style={{
									color: "#777777",
									fontWeight: "600",
									fontSize: 24,
								}}>{I18n.t('siteDisabled')}</Text>
							</View>}

						{siteEnabled && this.currentStep === 0 && <PaymentStepProduct onDataChanged={this.onProductChanged} isActive={this.currentStep === 0} />}
						{siteEnabled && this.currentStep === 1 && <PaymentStepUserInfo onDataChanged={this.onUserInfoChanged} isActive={this.currentStep === 1} />}
						{siteEnabled && this.currentStep === 2 && this.props.provider === "skrill" &&
							<PaymentStepSkrill onRestart={this.onRestart} isActive={this.currentStep === 2} />}
						{siteEnabled && this.currentStep === 2 && this.props.provider === "paypal" &&
							<PaymentStepPaypal onRestart={this.onRestart} isActive={this.currentStep === 2} />}
						{siteEnabled && this.currentStep === 2 && this.props.provider === "braintree" &&
							<PaymentStepBraintree onRestart={this.onRestart} isActive={this.currentStep === 2} />}
					</View>

					<View style={styles.controlsWrapper}>

						{siteEnabled && this.currentStep > 0 && !showRestart &&
							<Button onPress={this.onBack}>
								<Text style={{
									color: this.prevEnabled ? "#777" : "#EEE",
								}}>{I18n.t('back')}</Text>
							</Button>}

						<View style={{flex: 1}}></View>

						{siteEnabled && this.currentStep < PaymentSteps.NUM_STEPS - 1 &&
							<Button onPress={this.onNext} style={{
								backgroundColor: this.nextEnabled ? primaryColor : "#EEE",
								minWidth: 80
							}}>
								<Text style={{			
									color: "#FFF",
									fontWeight: "700"
								}}>{I18n.t('next') + " >"}</Text>
							</Button>}
					</View>
				</View>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	main: {
		flex: 1,
		margin: 0,
		maxWidth: 800,
		marginTop: 50
	},
	controlsWrapper: {
		flexDirection: 'row',
		justifyContent: 'flex-start',
		paddingBottom: 32,
		paddingTop: 32,
	}
});