import { getApiHost } from "../config/Backend";
import { error, log } from "../lib/debug/Logger";
import { Events } from "../lib/Events";
import Language from "../lib/i18n/Language";
import NetRequest from "../lib/network/NetRequest";
import Constants from 'expo-constants';
import LocalStorage from "../lib/utils/LocalStorage";
import TypeUtil from "../lib/utils/TypeUtil";
import Transaction from "../models/Transaction";
import AsyncUtil from "../lib/utils/AsyncUtil";

export class CheckoutResult
{
	tid?:string;
	pid?:string;
	success:boolean = false;
	status?:string;
	errors?:string[];
	paymentType?:string;
}

export default class TransactionStore
{
	public static EVENT_TRANSACTION_STATUS_CHANGED:string = "EVENT_TRANSACTION_STATUS_CHANGED";
	private static STORAGE_KEY_LAST_TRANSACTION = "lasttransaction";
	private static BACKGROUND_STATUS_UPDATE_INTERVAL_MS:number = 2000;

	transaction:Transaction = new Transaction();
	transactionStatus:string = Transaction.STATUS_PENDING;

	public async onAfterRootMounted():Promise<void>
	{
		var lastTransStr = await LocalStorage.get(TransactionStore.STORAGE_KEY_LAST_TRANSACTION);
		if (lastTransStr)
		{
			try
			{
				var lastTrans = JSON.parse(lastTransStr);
				if (lastTrans)
				{
					this.transaction.first_name = lastTrans.first_name;
					this.transaction.last_name = lastTrans.last_name;
					this.transaction.purpose = lastTrans.purpose || 0;
					this.transaction.email = lastTrans.email;
					this.transaction.tipwin_id = lastTrans.tipwin_id;
				}
			}
			catch (e)
			{
				error('error while loading data from last transaction');
			}
		}

		this.runStatusCheck();
	}

	async braintreeGenerateClientToken():Promise<string|undefined>
	{
		let uri:string = getApiHost() + '/api/v1/braintree/clienttoken';
		let response:any = await NetRequest.getText(uri);
		if (response && !TypeUtil.isNumber(response))
		{
			return response;
		}
		else
		{
			return undefined;
		}
	}

	getBraintreeCheckoutSubmitUrl():string
	{
		return getApiHost() + '/api/v1/braintree/checkout';
	}

	// getPaypalSubmitUrlBase():string
	// {
	// 	return getApiHost() + '/api/v1/paypal';
	// }

	preparePaymentBase()
	{
		this.transaction.provider_id = undefined;
		this.transaction.id = undefined;
		this.transaction.language = this.getTransactionLanguage();
		this.setTransactionStatus(Transaction.STATUS_PENDING);

		LocalStorage.set(TransactionStore.STORAGE_KEY_LAST_TRANSACTION, JSON.stringify(this.transaction));
	}

	async preparePaypalPayment()
	{
		this.preparePaymentBase();
	}

	async prepareBraintreePayment()
	{
		this.preparePaymentBase();
	}

	async paypalCheckout():Promise<CheckoutResult>
	{
		this.transaction.provider_id = undefined;
		this.transaction.id = undefined;
		this.transaction.language = this.getTransactionLanguage();
		this.setTransactionStatus(Transaction.STATUS_PENDING);

		LocalStorage.set(TransactionStore.STORAGE_KEY_LAST_TRANSACTION, JSON.stringify(this.transaction));

		let uri:string = getApiHost() + '/api/v1/paypal/checkout';

		let data = {
			//payment_method_nonce: nonce,
			//devicedata: deviceData,

			first_name: this.transaction.first_name,
			last_name: this.transaction.last_name,
			tipwin_id: this.transaction.tipwin_id,
			email: this.transaction.email,
			amount: this.transaction.amount,
			purpose: this.transaction.purpose,
			language: this.transaction.language,

			clientId: Constants.installationId,
			clientVersion: Constants.manifest.version
		};

		let response:any = await NetRequest.post(uri, data);

		//log(response);
		
		if (response && response.success && response.tid)
		{
			this.transaction.id = response.tid;
			this.transaction.provider_id = response.pid;

			this.setTransactionStatus(response.status);

			return response;

			// return {
			// 	success: true,
			// 	paymentType: response.paymentType
			// };

			//TEMP:
			// setTimeout(() => {
			// 	this.transactionStatus = SkrillService.STATUS_FAILED;
			// 	Events.fire(TransactionStore.EVENT_TRANSACTION_STATUS_CHANGED);
			// }, 5000);
		}
		else if (response && response.errors)
		{
			error(JSON.stringify(response.errors));
			this.setTransactionStatus(Transaction.STATUS_FAILED);
		}
		else
		{
			error('Unknown error');
			this.setTransactionStatus(Transaction.STATUS_FAILED);
		}

		return {
			success: false
		};
	}

	async paypalCaptureCheckout(orderId:string, payerId:string):Promise<CheckoutResult>
	{
		// this.transaction.provider_id = undefined;
		// this.transaction.id = undefined;
		// this.transaction.language = this.getTransactionLanguage();
		// this.setTransactionStatus(Transaction.STATUS_PENDING);

		// LocalStorage.set(TransactionStore.STORAGE_KEY_LAST_TRANSACTION, JSON.stringify(this.transaction));

		let uri:string = getApiHost() + '/api/v1/paypal/capture';

		let data = {
			pid: orderId,
			tid: this.transaction.id,
			payerId: payerId
		};

		let response:any = await NetRequest.post(uri, data);

		//log(response);
		
		if (response && response.success && response.tid)
		{
			this.transaction.id = response.tid;
			this.transaction.provider_id = response.pid;

			this.setTransactionStatus(response.status);

			return response;

			// return {
			// 	success: true,
			// 	paymentType: response.paymentType
			// };

			//TEMP:
			// setTimeout(() => {
			// 	this.transactionStatus = SkrillService.STATUS_FAILED;
			// 	Events.fire(TransactionStore.EVENT_TRANSACTION_STATUS_CHANGED);
			// }, 5000);
		}
		else if (response && response.errors)
		{
			error(JSON.stringify(response.errors));
			this.setTransactionStatus(Transaction.STATUS_FAILED);
		}
		else
		{
			error('Unknown error');
			this.setTransactionStatus(Transaction.STATUS_FAILED);
		}

		return {
			success: false
		};
	}

	async braintreeCheckout(nonce:string, deviceData: string):Promise<CheckoutResult>
	{
		this.transaction.provider_id = undefined;
		this.transaction.id = undefined;
		this.transaction.language = this.getTransactionLanguage();
		this.setTransactionStatus(Transaction.STATUS_PENDING);

		LocalStorage.set(TransactionStore.STORAGE_KEY_LAST_TRANSACTION, JSON.stringify(this.transaction));

		let uri:string = getApiHost() + '/api/v1/braintree/checkout';

		let data = {
			payment_method_nonce: nonce,
			devicedata: deviceData,

			first_name: this.transaction.first_name,
			last_name: this.transaction.last_name,
			tipwin_id: this.transaction.tipwin_id,
			email: this.transaction.email,
			amount: this.transaction.amount,
			purpose: this.transaction.purpose,
			language: this.transaction.language,

			clientId: Constants.installationId,
			clientVersion: Constants.manifest.version
		};

		let response:any = await NetRequest.post(uri, data);

		//log(response);
		
		if (response && response.success && response.tid)
		{
			this.transaction.id = response.tid;
			this.transaction.provider_id = response.pid;

			// When credit card was used, we give the braintree UI some more time to adjust
			if (response.paymentType === 'CREDIT_CARD')
				await AsyncUtil.sleepAsync(3000);
			
			this.setTransactionStatus(response.status);

			return {
				success: true,
				paymentType: response.paymentType
			};

			//TEMP:
			// setTimeout(() => {
			// 	this.transactionStatus = SkrillService.STATUS_FAILED;
			// 	Events.fire(TransactionStore.EVENT_TRANSACTION_STATUS_CHANGED);
			// }, 5000);
		}
		else if (response && response.braintreeErrors)
		{
			error(JSON.stringify(response.braintreeErrors));
			this.setTransactionStatus(Transaction.STATUS_FAILED);
		}
		else
		{
			error('Unknown error');
			this.setTransactionStatus(Transaction.STATUS_FAILED);
		}

		return {
			success: false
		};
	}

	async prepareSkrillPayment()
	{
		this.preparePaymentBase();

		let uri:string = getApiHost() + '/api/v1/transactions/prepare';

		let data = {
			first_name: this.transaction.first_name,
			last_name: this.transaction.last_name,
			tipwin_id: this.transaction.tipwin_id,
			email: this.transaction.email,
			amount: this.transaction.amount,
			purpose: this.transaction.purpose,
			language: this.transaction.language,

			clientId: Constants.installationId,
			clientVersion: Constants.manifest.version
		};

		let response:any = await NetRequest.post(uri, data);

		//log(response);
		
		if (response && response.pid && response.tid && !response.error)
		{
			this.transaction.id = response.tid;
			this.transaction.provider_id = response.pid;
			this.setTransactionStatus(this.transactionStatus);

			//TEMP:
			// setTimeout(() => {
			// 	this.transactionStatus = SkrillService.STATUS_FAILED;
			// 	Events.fire(TransactionStore.EVENT_TRANSACTION_STATUS_CHANGED, oldStatus, this.transactionStatus);
			// }, 5000);
		}
		else if (response && response.error)
		{
			error(response.error);
			this.setTransactionStatus(Transaction.STATUS_FAILED);
		}
		else
		{
			error('Unknown error');
			this.setTransactionStatus(Transaction.STATUS_FAILED);
		}
	}

	private getTransactionLanguage():string
	{
		if (Language.currentLanguage?.toLowerCase().startsWith("de"))
			return "DE";
		else
			return "EN";
	}

	runStatusCheck = async () =>
	{
		await this.statusCheckStep();

		setTimeout(this.runStatusCheck, TransactionStore.BACKGROUND_STATUS_UPDATE_INTERVAL_MS);
	}

	onPaymentStepCanceled(resetTransaction:boolean = true)
	{
		// Delete transaction on server
		let pid = this.transaction.provider_id;
		let tid = this.transaction.id;
		if (pid && tid)
			NetRequest.delete(getApiHost() + '/api/v1/transactions', { pid, tid });

		if (resetTransaction)
		{
			this.transaction.provider_id = undefined;
			this.transaction.id = undefined;
			this.setTransactionStatus(Transaction.STATUS_CANCELLED);
		}
	}

	onRestart()
	{
		this.transaction.provider_id = undefined;
		this.transaction.id = undefined;
		this.transaction.amount = 0;
		this.setTransactionStatus(Transaction.STATUS_PENDING);
	}

	async statusCheckStep()
	{
		let pid = this.transaction.provider_id;
		let tid = this.transaction.id;

		// console.log('------------------------------------');
		// console.log('tid: ' + tid);
		// console.log('pid: ' + pid);
		// console.log('status: ' + this.transactionStatus);

		if (!pid || !tid)
			return;

		if (Transaction.isCompletionStatus(this.transactionStatus))
			return; // no need to poll any longer
		
		let uri:string = getApiHost() + '/api/v1/transactions/status';
		let response:string|number = await NetRequest.getText(uri, { pid, tid });

		// still the same transaction active?
		if (this.transaction.provider_id === pid &&
			this.transaction.id === tid)
		{
			if (!TypeUtil.isNumber(response) && response.length > 0)
			{
				if (response !== this.transactionStatus)
				{
					log('new transaction status: ' + response);
					this.setTransactionStatus(response);
				}
			}	
		}
	}

	private setTransactionStatus(newStatus:string)
	{
		const oldStatus = this.transactionStatus;
		this.transactionStatus = newStatus;
		Events.fire(TransactionStore.EVENT_TRANSACTION_STATUS_CHANGED, oldStatus, newStatus);
	}
}