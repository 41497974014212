import React from 'react';
import { View, StyleSheet, StyleProp, ViewStyle, GestureResponderEvent, Text } from 'react-native';
import Button from './Button';

export interface Props
{
	text:string,
	size:number,
	fontSizeScale:number,
	backgroundColor:string,
	color:string,
	style?: StyleProp<ViewStyle>,
	onPress?: (event: GestureResponderEvent) => void,
	forceNativeTouchable:boolean
}

export default class CircledText extends React.PureComponent<Props>
{
	static defaultProps = {
		fontSizeScale: 1.0,
		backgroundColor: "#000",
		color: "#fff",
		forceNativeTouchable: false
	}

	renderContent():any
	{
		return (
			<Text style={[styles.text, {
				color: this.props.color,
				fontSize: Math.round(this.props.size / 2.5 * this.props.fontSizeScale),
				lineHeight: this.props.size,
				fontFamily: "OS700"
			}]}>
				{this.props.text}
			</Text>
		);
	}

	render()
	{
		if (this.props.onPress)
		{
			return (
				<Button
					style={[styles.main, {
						width: this.props.size,
						height: this.props.size,
						borderRadius: Math.ceil(this.props.size * 0.5),
						backgroundColor: this.props.backgroundColor,
						minWidth: 0,
						minHeight: 0,
					}, this.props.style]}
					rippleContainerBorderRadius={Math.ceil(this.props.size * 0.5)}
					onPress={this.props.onPress}
					forceNativeTouchable={this.props.forceNativeTouchable}
				>
					{this.renderContent()}
				</Button>
			);
		}
		else
		{
			return (
				<View
					style={[styles.main, {
						width: this.props.size,
						height: this.props.size,
						borderRadius: Math.ceil(this.props.size * 0.5),
						backgroundColor: this.props.backgroundColor,
					}, this.props.style]}
				>
					{this.renderContent()}
				</View>
			);
		}
	}
}

const styles = StyleSheet.create({
	main: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center"
	},
	text: {
		//flex: 1,
		textAlign: "center"
	}
});