import Analytics from './expo-analytics/analytics';
import { PageHit, Event } from './expo-analytics/hits';
import StringUtil from '../utils/StringUtil';
import Constants from 'expo-constants';
import TypeUtil from '../utils/TypeUtil';
import DeviceInfo from '../utils/DeviceInfo';
import TrackingEvent from './TrackingEvent';
import Matomo from './Matomo';
import LocalStorage from '../utils/LocalStorage';
import Assert from '../debug/Assert';
import { error } from '../debug/Logger';

export default abstract class Tracking
{
	public static readonly ENABLED_IN_DEVELOPMENT:boolean = false;
	public static readonly BACKEND_GA_ENABLED:boolean = false;
	public static readonly BACKEND_MATOMO_ENABLED:boolean = false;

	public static readonly EVENT_PAGEVIEW:string = "pageView";

	private static ga:Analytics;

	public static clientId:string; // this device
	private static userId:string|undefined; // the logged in user - not used at the moment to prevent tracking of personal data (GDPR)
	public static clientSessionId:string;
	public static userFirstVisitDate?:number; // unix timestamp
	public static userLastVisitDate?:number; // unix timestamp
	public static userVisitCount?:number;
	private static currentPageView:string = "";
	private static queue:any[] = [];

	public static async init(hostname:string):Promise<void>
	{
		if (Tracking.BACKEND_GA_ENABLED)
			Tracking.ga = new Analytics('UA-123456789-1'); //set GA tracking ID if needed

		if (Tracking.BACKEND_MATOMO_ENABLED)
			Matomo.init(hostname + "/matomo", "1");

		Tracking.clientId = Constants.installationId;
		Tracking.clientSessionId = StringUtil.randomStr(16);

		Tracking.userVisitCount = await LocalStorage.getNumber("userVisitCount");
		if (Tracking.userVisitCount === undefined)
			Tracking.userVisitCount = 0;
		Tracking.userVisitCount++;
		await LocalStorage.set("userVisitCount", Tracking.userVisitCount);

		Tracking.userFirstVisitDate = await Tracking.initTrackingNumber("userFirstVisitDate", Math.round(Date.now() / 1000));

		Tracking.userLastVisitDate = await LocalStorage.getNumber("userLastVisitDate");
		await LocalStorage.set("userLastVisitDate", Math.round(Date.now() / 1000));

		if (Tracking.userVisitCount === 1)
			Tracking.sendEvent("app.install");
		Tracking.sendEvent("app.start");

		Tracking.onPageView("version/" + Constants.manifest.version);
	}

	public static setHostname(hostname:string):void
	{
		Matomo.setHostname(hostname + "/matomo");
	}

	private static async initTrackingNumber(key:string, defaultValue:number):Promise<number>
	{
		let result = await LocalStorage.getNumber(key);
		if (result === undefined)
		{
			result = defaultValue;
			LocalStorage.set(key, result.toString());
		}
		return result;
	}

	public static onError(err:any):void
	{
		console.log(err);

		if (TypeUtil.isString(err))
		{
			Tracking.sendEvent("error", {message: err});

			if (Tracking.isEnabled && Tracking.ga)
				Tracking.ga.event(new Event('debug', 'error', error, 0));
		}
		else if (err.message !== undefined || err.stack !== undefined)
		{
			Tracking.sendEvent("error", {
				message: err.message,
				stack: err.stack
			});

			if (Tracking.isEnabled && Tracking.ga)
				Tracking.ga.event(new Event('debug', 'error', err.message, 0));
		}
		else
		{
			Tracking.sendEvent("error", err);
		}
	}

	public static onPageView(pageName:string):void
	{
		if (pageName !== Tracking.currentPageView)
		{
			Tracking.currentPageView = pageName;

			Tracking.sendEvent(Tracking.EVENT_PAGEVIEW, {
				url: "/" + pageName
			});
		}
	}

	public static async sendEvent(eventName:string, eventParameters:any = {}, forcedDate:any = undefined):Promise<void>
	{
		if (!Tracking.isEnabled)
			return;

		if (Tracking.clientId === undefined)
		{
			Tracking.queue.push({name: eventName, params: eventParameters, forcedDate: Date.now()});
			return new Promise((resolve, reject) => { return resolve(undefined);});
		}

		if (eventName === Tracking.EVENT_PAGEVIEW)
		{
			Assert.isValid(eventParameters.url);
			if (Tracking.ga)
				Tracking.ga.hit(new PageHit(eventParameters.url));
		}

		let e = Tracking.createEvent(eventName);
		if (Tracking.BACKEND_MATOMO_ENABLED)
		{
			Matomo.sendEvent(e, eventParameters);
		}
	}

	private static createEvent(eventName:string):TrackingEvent
	{
		let result = new TrackingEvent();

		result.eventName = eventName;

		const di = DeviceInfo.instance;

		if (di.tablet)
			result.deviceType = "tablet";
		else
			result.deviceType = "phone";

		result.deviceName = di.deviceName;
		result.screenWidth = di.width;
		result.screenHeight = di.height;

		result.os = di.os;
		result.osVersion = di.osVersion;

		result.clientId = Tracking.clientId;
		result.userId = Tracking.userId;
		result.sessionId = Tracking.clientSessionId;
		result.buildVersion = Constants.manifest.version;

		result.userFirstVisitDate = Tracking.userFirstVisitDate;
		result.userLastVisitDate = Tracking.userLastVisitDate;
		result.userVisitCount = Tracking.userVisitCount;

		return result;
	}

	private static get isEnabled():boolean
	{
		if (__DEV__)
		{
			return (Tracking.BACKEND_GA_ENABLED || Tracking.BACKEND_MATOMO_ENABLED) && Tracking.ENABLED_IN_DEVELOPMENT;
		}
		else
		{
			return Tracking.BACKEND_GA_ENABLED || Tracking.BACKEND_MATOMO_ENABLED;
		}
	}
}