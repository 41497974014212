import React from 'react';
import { observer, inject } from 'mobx-react';
import { AppStoreName, appStoreDefaultProps, AppStoreProps } from '../stores/AppStore';
import { StyleSheet, View } from 'react-native';
import Text from '../lib/components/Text';
import I18n from '../lib/i18n';
import { Events } from '../lib/Events';
import Language from '../lib/i18n/Language';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPhoneSquare, faFax, faEnvelope, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import DeviceInfo from '../lib/utils/DeviceInfo';
import Anchor from '../lib/components/Anchor';


export interface Props extends AppStoreProps
{
}

@inject(AppStoreName) @observer
export default class PageCopyright extends React.Component<Props>
{
	static defaultProps = appStoreDefaultProps;

	componentDidMount()
	{
		Events.listen(Language.EVENT_LANGUAGE_CHANGED, () => { this.forceUpdate(); });
		Events.listen(DeviceInfo.EVENT_SCREEN_CHANGED, () => { this.forceUpdate(); });
	}

	render()
	{
		let paddingHorizontal:number = 15;
		const w = DeviceInfo.instance.width;
		if (w > 1180)
			paddingHorizontal += (w - 1180) * 0.5;
		paddingHorizontal += 12;

		const smallVersion:boolean = w <= 800;

		return (
			<View style={[styles.main, {paddingHorizontal}]}>
				<Text style={textStyles.copyright}>{I18n.t('copyright')}</Text>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	main: {
		width: "100%",
		backgroundColor: '#FFF',
		paddingTop: 18,
		paddingBottom: 16,
		flexDirection: 'row',
		justifyContent: 'flex-start'
	}
});

const textStyles = {
	copyright: {
		fontSize: 13,
		color: '#7f7b7b',
		fontWeight: "300"
	},
};