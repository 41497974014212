import Assert from "./debug/Assert";
import { error, log } from "./debug/Logger";
import TypeUtil from "./utils/TypeUtil";

interface ListenerOptions
{
	once?: boolean
	priority?: number
}

export class Events
{
	private static _listeners:any = {};
	private static _insideEvents:number = 0;
	private static _id:number = 1000;

	private static _listenersToRemove:any[] = [];

	public static readonly CANCEL = new Object();
	public static readonly CONTINUE = new Object();


	public static listenOnce(type:string, callback:Function, options : ListenerOptions = { }):number
	{
		Assert.isTrue(type !== undefined, "Events:listen: invalid type argument");

		options.once = true;
		return this.listen(type, callback, options);
	}

	public static listen(type:string|string[], callback:Function, options : ListenerOptions = { }):number
	{
		Assert.isTrue(type !== undefined, "Events:listen: invalid type argument");

		if (typeof type === "string")
		{
			return this._doAddListener(type, callback, options);
		}
		else
		{
			for (let i=0; i<type.length; i++)
				this._doAddListener(type[i], callback, options );

			return 0;
		}
	}

	public static _doAddListener(type:string, callback:Function, { once, priority } : ListenerOptions = { }):number
	{
		Assert.isValid(type, "Events: not valid type");
		Assert.isValid(callback, "Events: not valid callback");

		if (this._listeners[type] === undefined)
			this._listeners[type] = [];

		if (__DEV__)
		{
			let listeners = this._listeners[type];
			for (let i = 0; i < this._listeners.length; i++)
			{
				//if ((this._listeners[i].type == type) && (core.functionEquals(this._listeners[i].callback, callback)))
				if ((this._listeners[i].type == type) && (this._listeners[i].callback === callback))
				{
					error("listener already added: ", type);
					return -1;
				}
			}
		}

		if (priority === undefined)
			priority = 0;

		this._id++;
		let entry = { type: type, callback: callback, id: this._id, once: once, priority: priority };

		let listeners = this._listeners[type];
		let count = listeners.length;
		let index = 0;
		for (index=0; index<count; index++)
		{
			if (listeners[index].priority < priority)
				break;
		}

		listeners.splice(index, 0, entry);

		return entry.id;
	}

	public static removeListener(type:any, callback:Function):void
	{
		if (this._insideEvents > 0)
		{
			log("queue remove listener:", type);
			for (let i=0; i<this._listenersToRemove.length; i++)
			{
				let l = this._listenersToRemove[i];
				if ((l.type === type) && (l.callback === callback))
					return
			}
			this._listenersToRemove.push({ type: type, callback: callback })
			return;
		}


		if (TypeUtil.isNumber(type))
		{
			log("**** removing by id not supported, need to sort out performance");
			debugger;

			let id = type;

			for (let i = 0; i < this._listeners.length; i++)
			{
				if (this._listeners[i].id == id)
				{
					this._listeners.splice(i, 1);
					return;
				}
			}

			log("listener not found");
			debugger;
		}
		else
		{
			let listeners = this._listeners[type];
			if (!listeners)
			{
				error("Events: remove listener was not registered:" + type);
				return;
			}
			for (let i = 0; i < listeners.length; i++)
			{
				if (listeners[i].callback === callback)
				{
					listeners.splice(i, 1);
					return;
				}
			}
		}
	}

	public static fire(type:string, ...data:any[]) : any
	{
		Assert.isTrue(type !== undefined, "Events:fire: invalid type argument");

		this._insideEvents++;

		log("", "EVENT \"" + type + "\"", ...data);

		let result;

		let listeners = this._listeners[type];
		if (listeners)
		{
			let numListeners = this._listeners[type].length;
			for (let i = 0; i < numListeners; i++)
			{
				if (listeners[i].once)
					this._listenersToRemove.push({ type: type, callback: listeners[i].callback })

				result = listeners[i].callback.apply(window, data);
				if (result === Events.CANCEL)
					break;
			}
		}

		this._insideEvents--;

		if (this._insideEvents === 0)
		{
			for (let i = 0; i < this._listenersToRemove.length; i++)
			{
				this.removeListener(this._listenersToRemove[i].type, this._listenersToRemove[i].callback);
			}
			this._listenersToRemove = [];
		}

		if (result === Events.CANCEL)
			return false;
		return true;
	}

	public static query(type:string, ...data:any[]) : any[]
	{
		Assert.isTrue(type !== undefined, "Events:query: invalid type argument");

		this._insideEvents++;

		log("", "query \"" + type + "\"", ...data);

		let result:any[] = [];

		let listeners = this._listeners[type];
		if (listeners)
		{
			let numListeners = this._listeners[type].length;
			for (let i = 0; i < numListeners; i++)
			{
				if (listeners[i].once)
					this._listenersToRemove.push({ type: type, callback: listeners[i].callback })

				let h = listeners[i].callback.apply(window, data);
				result.push(h);
			}
		}

		this._insideEvents--;

		if (this._insideEvents === 0)
		{
			for (let i=0; i<this._listenersToRemove.length; i++)
			{
				this.removeListener(this._listenersToRemove[i].type, this._listenersToRemove[i].callback);
			}
			this._listenersToRemove = [];
		}

		return result;
	}
}
