import React from 'react';
import { Linking, TouchableOpacity } from 'react-native';
import TypeUtil from '../utils/TypeUtil';
import { error } from "../debug/Logger";
import Text from './Text';
import Tracking from '../tracking/Tracking';
import Button from './Button';
import DeviceInfo from '../utils/DeviceInfo';

export interface Props
{
	href:string,
	target?:string,
	forceTextMode:boolean
	onPress?:() => void,
	style?:any,
	textStyle?:any
}

export default class Anchor extends React.Component<Props>
{
	static defaultProps = {
		forceTextMode: false
	};

	onPress = () =>
	{
		try
		{
			if (this.props.onPress)
			{
				this.props.onPress();
			}
			else
			{
				if (DeviceInfo.instance.web)
				{
					window.open(this.props.href, this.props.target);
				}
				else
				{
					const uri = this.props.href;
					if (this.props.target === "_blank" || uri.startsWith("mailto:") || uri.startsWith("tel:"))
					{
						Linking.canOpenURL(uri).then(supported =>
						{
							if (supported)
							{
								Linking.openURL(uri);
							}
							else
							{
								//WebBrowser.openBrowserAsync(uri);
							}
						});
					}
					else
					{
						//WebBrowser.openBrowserAsync(uri);
					}
				}

				Tracking.onPageView("weblink/" + uri);
			}
		}
		catch (e)
		{
			error("error while opening anchor uri: " + e);
		}
	}

	render()
	{
		const isString = this.props.forceTextMode || TypeUtil.isString(this.props.children);

		return (
			<Button {...this.props} onPress={this.onPress}>
				{isString &&
					<Text style={this.props.textStyle}>
						{this.props.children}
					</Text>}
				{!isString &&
					this.props.children}
			</Button>
		);
	}
}