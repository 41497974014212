import AsyncStorage from '@react-native-community/async-storage';
import { error } from "../debug/Logger";
import TypeUtil from "./TypeUtil";

export default abstract class LocalStorage
{
	public static async set(key:string, value:any):Promise<boolean>
	{
		try
		{
			if (TypeUtil.isString(value))
				await AsyncStorage.setItem(key, value);
			else
				await AsyncStorage.setItem(key, value.toString());
			return true;
		}
		catch (e)
		{
			error("error while setting local storage '" + key + "': " + e);
			return false;
		}
	}

	public static async remove(key:string):Promise<boolean>
	{
		try
		{
			await AsyncStorage.removeItem(key);
			return true;
		}
		catch (e)
		{
			error("error while removing local storage '" + key + "': " + e);
			return false;
		}
	}

	public static clear():boolean
	{
		try
		{
			AsyncStorage.clear();
			return true;
		}
		catch (e)
		{
			error("error while clearing local storage: " + e);
			return false;
		}
	}

	public static async get(key:string, defaultValue:string | undefined = undefined):Promise<string | undefined>
	{
		try
		{
			const result = await AsyncStorage.getItem(key);
			if (result === undefined)
				return defaultValue;
			if (result === null)
				return undefined;
			return result;
		}
		catch (e)
		{
			error("error while getting local storage: " + key);
			return defaultValue;
		}
	}

	public static async getList(key:string):Promise<any[]>
	{
		const r = await LocalStorage.get(key, undefined);
		if (r && r.length > 0)
		{
			return JSON.parse(r);
		}
		else
		{
			return [];
		}
	}

	public static async setList(key:string, list):Promise<boolean>
	{
		return await LocalStorage.set(key, JSON.stringify(list || []));
	}

	public static async getNumber(key:string):Promise<number|undefined>
	{
		const result = await LocalStorage.get(key, undefined);
		return result !== undefined ? parseInt(result, 10) : undefined;
	}
}