import React from 'react';
import { View, StyleSheet } from 'react-native';
import { AppLoading } from 'expo';
import { observer, inject } from 'mobx-react';
import { AppStoreName, appStoreDefaultProps, AppStoreProps } from '../stores/AppStore';
import Language from '../lib/i18n/Language';
import DeviceInfo from '../lib/utils/DeviceInfo';
import { Events } from '../lib/Events';
import Text from '../lib/components/Text';
import PaymentSteps from './PaymentSteps';
import PageFooter from './PageFooter';
import PageHeader from './PageHeader';
import PageCopyright from './PageCopyright';

export interface Props extends AppStoreProps
{
}

@inject(AppStoreName) @observer
export default class AppRoot extends React.Component<Props>
{
	static defaultProps = appStoreDefaultProps;

	constructor(props:Props)
	{
		super(props);
		//JsProfiler.checkPoint("AppRoot.ctor");

		// Prevent font-scaling via OS settings
		(Text as any).defaultProps = (Text as any) || {};
		(Text as any).defaultProps.allowFontScaling = false;
	}

	async componentDidMount()
	{
		//JsProfiler.checkPoint("AppRoot.didMount");

		await this.props.appStore.onAfterRootMounted();
		Events.listen(Language.EVENT_LANGUAGE_CHANGED, () => { this.forceUpdate(); });
	}

	onMainLayout = ({ nativeEvent }) =>
	{
		const windowWidth = nativeEvent.layout.width;
		const windowHeight = nativeEvent.layout.height;
		DeviceInfo.instance.onScreenMeasured(windowWidth, windowHeight);
	}

	additionalLoadingTask = async():Promise<void> =>
	{
		//console.log("additional loading");
		//await AsyncUtil.sleepAsync(2000);
		//console.log("additional loading done");
		await this.props.appStore.loading();
	}

	onLoadingFinished = () =>
	{
		const loadingStore = this.props.appStore.appLoadingStore;
		loadingStore.onLoadingFinished();

		this.forceUpdate();
	}

	render()
	{
		const loadingStore = this.props.appStore.appLoadingStore;

		if (loadingStore.isAppLoading)
		{
			return (
				<AppLoading
					startAsync={loadingStore.startLoading(this.additionalLoadingTask)}
					onError={loadingStore.onLoadingError}
					onFinish={this.onLoadingFinished}
					autoHideSplash={true}
				/>
			);
		}

		if (!Language.currentLanguage)
			return null;

		return (
			<View
				style={styles.main}
				onLayout={this.onMainLayout}
			>
				<PageHeader />

				<View style={styles.contentWrapper}>
					<PaymentSteps provider="paypal" />
				</View>

				<View style={{flex: 1}}></View>

				<PageFooter />
				<PageCopyright />
			</View>
		);
	}
}

const styles = StyleSheet.create({
	main: {
		width: '100%',
		alignItems: 'center',
		minHeight: '100%'
	},
	contentWrapper: {
		width: '100%',
		maxWidth: 800,
		paddingHorizontal: 24
	}
});