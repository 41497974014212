import Assert from "../debug/Assert"
import moment from 'moment';

export const initMomentJs = (deviceLocale:string) =>
{
	Assert.isValid(deviceLocale);

	const _loc:string = deviceLocale.toLowerCase();
	if (_loc.startsWith("de"))
		moment.locale("de");
	else
		moment.locale("en-ie");
}