import 'mobx-react-lite/batchingForReactNative'
//import JsProfiler from './src/lib/utils/JsProfiler';

//JsProfiler.checkPoint("App.require");

import React from 'react';
import { Provider } from "mobx-react";
import stores from './src/stores';
import AppRoot from './src/components/AppRoot';
import Constants from 'expo-constants';
import { RollbarClient } from './src/lib/tracking/Rollbar';

require('moment/locale/de.js');
require('moment/locale/en-ie.js');

//JsProfiler.logLoadedModules();

export default function App()
{
	//JsProfiler.checkPoint("App.ctor");

	return (
		<Provider {...stores}>
			<AppRoot
				appStore={stores.appStore}
				navigation={undefined}
			/>
		</Provider>
	);
}

if (!__DEV__)
{
// 	Sentry.init({
// 		dsn: 'https://8ba___________f5ff88fe7c175@sentry.io/18___97',
// 		enableInExpoDevelopment: false,
// 		debug: false
// 	});
// 	if (Constants.manifest.revisionId)
// 		Sentry.setRelease(Constants.manifest.revisionId);

 	RollbarClient.init({
		accessToken: "cec84c3ad097487ea0701fec3cad4bf8",
		payload: {
			environment: "production",
		},
		logLevel: "warning",
		appVersion: Constants.manifest.version,
		enabled: true,
		verbose: false,
		captureIp: 'anonymize',
	});

	//if (RollbarClient.instance)
	//	RollbarClient.instance.error("Test Error");
}
