import { Platform } from 'react-native';
import Rollbar from 'rollbar/src/react-native/rollbar';
//import JsProfiler from '../utils/JsProfiler';

//JsProfiler.checkPoint("Rollbar.require");

export class RollbarClient
{
	public static instance:RollbarClient|undefined;

	private config:Configuration;
	private rollbar:Rollbar;

	public static init(config:any):void
	{
		RollbarClient.instance = new RollbarClient(config);
	}

	constructor(config:any)
	{
		if (config instanceof Configuration)
		{
			this.config = config;
		}
		else
		{
			this.config = new Configuration(config.accessToken, config);
		}
		this.config.setPlatform(Platform.OS);
		const jsonConfig = this.config.toJSON();
		this.rollbar = new Rollbar(jsonConfig);
	}

	captureUncaughtExceptions = () =>
	{
		if (ErrorUtils)
		{
			const previousHandler = ErrorUtils.getGlobalHandler();
			ErrorUtils.setGlobalHandler((error, isFatal) =>
			{
				if (this.config.captureUncaught && this.config.shouldSend())
				{
					this.error(error, undefined, (queued) =>
					{
						if (previousHandler)
						{
							previousHandler(error, isFatal);
						}
					});
				}
				else if (previousHandler)
				{
					previousHandler(error, isFatal);
				}
			});
		}
	}

	captureUnhandledRejections = () =>
	{
		const tracking = require('promise/setimmediate/rejection-tracking');
		const client = this;
		tracking.enable({
			allRejections: true,
			onUnhandled: function(id, error) { client.error(error); },
			onHandled: function() {}
		});
	}

	log = (obj:any|undefined = undefined, extra:any|undefined = undefined, callback:any|undefined = undefined) =>
	{
		if (!this.config.shouldSend())
		{
			if (callback)
			{
				callback(false);
			}
			return;
		}

		this.rollbar.log(obj, extra, callback);
	}

	debug = (obj, extra:any|undefined = undefined, callback:any|undefined = undefined) => {
		return this.rollbar.debug(obj, extra, callback);
	}

	info = (obj, extra:any|undefined = undefined, callback:any|undefined = undefined) => {
		return this.rollbar.info(obj, extra, callback);
	}

	warning = (obj, extra:any|undefined = undefined, callback:any|undefined = undefined) => {
		return this.rollbar.warning(obj, extra, callback);
	}

	error = (obj:any, extra:any|undefined = undefined, callback:any|undefined = undefined) => {
		return this.rollbar.error(obj, extra, callback);
	}

	critical = (obj, extra:any|undefined = undefined, callback:any|undefined = undefined) => {
		return this.rollbar.critical(obj, extra, callback);
	}

	setPerson = (id, name, email) => {
		this.rollbar.setPerson({id, name, email});
	}

	clearPerson = () => {
		this.rollbar.clearPerson();
	}
}

export class Configuration
{
	public version:string;
	public accessToken:string;
	public environment:string|undefined;
	public platform:string|undefined;
	public logLevel:string;
	public reportLevel:string;
	public endpoint:string;
	public appVersion:string|undefined;
	public codeBundleId:string|undefined
	public releaseStage:string|undefined
	public enabledReleaseStages:any|undefined
	public captureUncaught:boolean;
	public captureUnhandledRejections:boolean;
	public payload:any;
	public enabled:boolean;
	public verbose:boolean;
	public transform:any;
	public rewriteFilenamePatterns:any;

	constructor(accessToken, options)
	{
		options = options || {};
		this.version = "0.6.1";
		this.accessToken = accessToken;
		this.environment = options.environment;
		this.logLevel = options.logLevel || 'debug';
		this.reportLevel = options.reportLevel || 'debug';
		this.endpoint = options.endpoint || 'https://api.rollbar.com/api/1/item/';
		this.appVersion = options.appVersion || undefined;
		this.codeBundleId = options.codeBundleId || undefined;
		this.releaseStage = options.releaseStage || undefined;
		this.enabledReleaseStages = options.enabledReleaseStages || undefined;
		this.captureUncaught = options.captureUncaught !== undefined ? options.captureUncaught : true;
		this.captureUnhandledRejections = options.captureUnhandledRejections !== undefined ? options.captureUnhandledRejections : !__DEV__;
		this.payload = options.payload || {};
		this.enabled = options.enabled === undefined ? true : options.enabled;
		this.verbose = options.verbose || false;
		this.transform = options.transform;
		this.rewriteFilenamePatterns = options.rewriteFilenamePatterns;
	}

	shouldSend = () =>
	{
		return !this.releaseStage ||
			!this.enabledReleaseStages ||
			this.enabledReleaseStages.includes(this.releaseStage);
	}

	setPlatform = (platform) =>
	{
		if (this.platform === undefined)
		{
			if (platform === 'ios' || platform === 'android')
			{
				this.platform = platform;
			}
			else
			{
				this.platform = 'client';
			}
		}
	}

	toJSON = () =>
	{
		var result:any = {
			accessToken: this.accessToken,
			endpoint: this.endpoint,
			platform: this.platform,
			logLevel: this.logLevel,
			reportLevel: this.reportLevel,
			enabled: this.enabled,
			verbose: this.verbose,
			transform: this.transform,
			rewriteFilenamePatterns: this.rewriteFilenamePatterns,
			payload: {
				codeBundleId: this.codeBundleId,
				releaseStage: this.releaseStage,
				enabledReleaseStages: this.enabledReleaseStages,
				appVersion: this.appVersion,
				...this.payload
			},
			notifier: {
				name: 'rollbar-react-native',
				version: this.version
			}
		};
		if (this.environment)
		{
			result.environment = this.environment;
		}
		return result;
	}
}
