import React from 'react';
import { Text as RNText, TextProps, View } from 'react-native';
import TypeUtil from '../utils/TypeUtil';
import StyleUtil from '../utils/StyleUtil';
import Assert from '../debug/Assert';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import Button from './Button';
import { error, log } from '../debug/Logger';
import Tracking from '../tracking/Tracking';


export interface Props extends TextProps
{
	style?:any,
	urlSupport?:boolean
}

export default class Text extends React.PureComponent<Props>
{
	private static onPressEnabled:boolean = true;

	private static onPress = (uri:string) => () =>
	{
		if (!Text.onPressEnabled)
			return;

		try
		{
			// Prevent super-fast onPress events
			Text.onPressEnabled = false;
			setTimeout(() => {
				Text.onPressEnabled = true;
			}, 250);

			//TODO:
			//WebBrowser.openBrowserAsync(uri.trim());
			Tracking.onPageView("weblink/" + uri);
		}
		catch (e)
		{
			error("error while opening anchor uri: " + e);
		}
	}

	private static convertLine(line:string, styleArray:any[], urlSupport:boolean, keyPrefix:string|undefined = undefined):Array<JSX.Element|string> | undefined
	{
		let lines:Array<JSX.Element | string> = [];

		if (line && urlSupport)
		{
			let startIdx = line.indexOf("http");
			if (startIdx >= 0)
			{
				let endIdx = -1;
				for (let i = startIdx; i < line.length; ++i)
				{
					const c = line.charAt(i);
					if (c === " " || c === "\n" || c === "<" || c === "\t")
					{
						endIdx = i;
						break;
					}
				}

				const p0 = startIdx > 0 ? line.substring(0, startIdx) : undefined;
				const p1 = line.substring(startIdx, endIdx >= 0 ? endIdx : undefined);
				const p2 = endIdx >= 0 ? line.substring(endIdx) : undefined;

				//  console.log("startIdx", startIdx);
				//  console.log("endIdx", endIdx);

				//  console.log("p0", p0);
				//  console.log("p1", p1);
				//  console.log("p2", p2);

				if (p0)
				{
					let cl = Text.convertLine(p0, styleArray, urlSupport, "_li0_");
					if (cl)
					{
						lines = [...lines, ...cl];
					}
					else
					{
						lines.push(p0);
					}
				}

				if (p1)
					lines.push(p1);

				if (p2)
				{
					let cl = Text.convertLine(p2, styleArray, urlSupport, "_li2_");
					if (cl)
					{
						lines = [...lines, ...cl];
					}
					else
					{
						lines.push(p2);
					}
				}

				return lines;
			}
		}

		if (lines.length === 0)
			if (!line || line.indexOf("<b>") < 0)
				return undefined;

		let boldParts:string[] = line.match(/<b>.*?<\/b>/g) as string[];
		if (boldParts)
		{
			const boldStyle = {fontFamily: "OS700"};

			Assert.isValid(boldParts);
			let str = line;

			for (let i = 0; i < boldParts.length; ++i)
			{
				const idx = str.indexOf(boldParts[i]);
				const preText = str.substring(0, idx);

				if (preText && preText.length > 0)
					lines.push(preText);

				const boldText = boldParts[i].substring(3, boldParts[i].length - 4);
				if (boldText.length > 0)
					lines.push(<RNText key={(keyPrefix ? keyPrefix : "") + "bold" + i} style={[...styleArray, boldStyle]}>{boldText}</RNText>);

				str = str.substring(idx + boldParts[i].length);
			}

			if (str.length > 0)
				lines.push(str);
		}

		return lines;
	}

	private static convertLines(lines:string[], styleArray:any[], urlSupport:boolean):Array<JSX.Element | string>
	{
		let result:Array<JSX.Element | string> = [];

		for (let i = 0; i < lines.length; ++i)
		{
			let line = Text.convertLine(lines[i], styleArray, urlSupport);
			if (line)
			{
				result = [...result, ...line];
			}
			else
			{
				result.push(lines[i]);
			}
		}

		return result;
	}

	private static isLinkUrl(str:any):str is string
	{
		if (TypeUtil.isString(str))
			return str.startsWith("http://") || str.startsWith("https://");
		else
			return false;
	}

	private static getPropertyFromStyleArray(styleArray:any[], property:string):string|undefined
	{
		for (let i = styleArray.length - 1; i >= 0; --i)
		{
			if (styleArray[i][property] !== undefined)
				return styleArray[i][property];
		}
		return undefined;
	}

	private static getColorFromStyleArray(styleArray:{color?:string, fontFamily?:string}[]):string|undefined
	{
		for (let i = styleArray.length - 1; i >= 0; --i)
		{
			if (styleArray[i].color)
				return styleArray[i].color;
		}
		return undefined;
	}

	private static getFontSizeFromStyleArray(styleArray:{color?:string, fontFamily?:string, fontSize?:number}[]):number|undefined
	{
		for (let i = styleArray.length - 1; i >= 0; --i)
		{
			if (styleArray[i].fontSize !== undefined)
				return styleArray[i].fontSize;
		}
		return 14;
	}

	private static groupLines(lines:Array<JSX.Element | string>, props:any, styleArray:{color?:string, fontFamily?:string}[]):any
	{
		// separate by http-links

		let groups:Array<{
			items:Array<JSX.Element | string>,
			link?:string
		}> = [];

		for (let i = 0; i < lines.length; ++i)
		{
			const line = lines[i];
			if (Text.isLinkUrl(line))
			{
				groups.push({
					items: [],
					link: line
				});
			}
			else
			{
				if (groups.length === 0 || groups[groups.length - 1].link)
					groups.push({
						items: [],
						link: undefined
					});

				groups[groups.length - 1].items.push(line);
			}
		}

		let containsLinks:boolean = false;

		let result:JSX.Element[] = [];
		for (let i = 0; i < groups.length; ++i)
		{
			const g = groups[i];
			if (g.link)
			{
				containsLinks = true;
				result.push(
					<Button key={"link" + i}
						style={{
							minWidth: 0,
							paddingHorizontal: 4,
							alignItems: "center",
							justifyContent: "center",
							marginLeft: Text.getPropertyFromStyleArray(styleArray, "marginLeft"),
							maxWidth: Text.getPropertyFromStyleArray(styleArray, "maxWidth")
						}}
						onPress={Text.onPress(g.link)}
					>
						<View style={{
							flex: 1,
							flexDirection: "row",
						}}>
						<RNText
							{...props}
							style={[{
								textDecorationLine: "underline",
								marginRight: 4,
								fontSize: Text.getPropertyFromStyleArray(styleArray, "fontSize"),
								color: Text.getColorFromStyleArray(styleArray)
							}]}
							numberOfLines={1}
						>
							{g.link}
						</RNText>
						<MaterialCommunityIcons
							color={Text.getColorFromStyleArray(styleArray)}
							name={"open-in-new"}
							size={Text.getFontSizeFromStyleArray(styleArray)}
						/>
						</View>
					</Button>
				);
			}
			else
			{
				result.push(
					<RNText {...props} style={styleArray} key={"item" + i}>
						{g.items}
					</RNText>
				);
			}
		}

		if (containsLinks)
		{
			return (
				<React.Fragment>
					{result}
				</React.Fragment>
			);
		}
		else
		{
			return result;
		}
	}

	render()
	{
		let styleArray = [{color: "#555", fontFamily: "OS400"}];

		const isUrlSupport = this.props.urlSupport !== false;

		if (this.props.style)
		{
			if (TypeUtil.isArray(this.props.style))
			{
				const styles = this.props.style as any[];
				const len = styles.length;
				for (let i = 0; i < len; ++i)
					styleArray.push(StyleUtil.convertFontWeightIntoFontFamily(styles[i]));
			}
			else
			{
				styleArray.push(StyleUtil.convertFontWeightIntoFontFamily(this.props.style));
			}
		}

		if (TypeUtil.isStringArray(this.props.children))
		{
			const {children, style, urlSupport, onPress, ...props} = this.props;
			let lines:Array<JSX.Element | string> = Text.convertLines(children, styleArray, isUrlSupport);

			return Text.groupLines(lines, props, styleArray);
		}
		else if (TypeUtil.isString(this.props.children))
		{
			const {children, style, urlSupport, onPress, ...props} = this.props;
			let lines:Array<JSX.Element | string> | undefined = Text.convertLine(children, styleArray, isUrlSupport);

			if (lines)
			{
				if (TypeUtil.isString(lines))
				{
					return (
						<RNText {...props} style={styleArray}>
							{lines}
						</RNText>
					);
				}
				else
				{
					return Text.groupLines(lines, props, styleArray);
				}
			}
			else
			{
				return (<RNText {...props} style={styleArray}>{this.props.children}</RNText>)
			}
		}
		else
		{
			const {children, style, urlSupport, onPress, ...props} = this.props;
			return (<RNText {...props} style={styleArray}>{this.props.children}</RNText>)
		}
	}
}