import React from 'react';
import { observer, inject } from 'mobx-react';
import { AppStoreName, appStoreDefaultProps, AppStoreProps } from '../stores/AppStore';
import { Image, StyleSheet, View } from 'react-native';
import Text from '../lib/components/Text';
import I18n from '../lib/i18n';
import { Events } from '../lib/Events';
import Language from '../lib/i18n/Language';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPhoneSquare, faFax, faEnvelope, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import DeviceInfo from '../lib/utils/DeviceInfo';
import Anchor from '../lib/components/Anchor';
import Button from '../lib/components/Button';
import { primaryColor } from '../config/Color';
import MathUtil from '../lib/utils/MathUtil';


export interface Props extends AppStoreProps
{
}

@inject(AppStoreName) @observer
export default class PageHeader extends React.Component<Props>
{
	static defaultProps = appStoreDefaultProps;

	componentDidMount()
	{
		Events.listen(Language.EVENT_LANGUAGE_CHANGED, () => { this.forceUpdate(); });
		Events.listen(DeviceInfo.EVENT_SCREEN_CHANGED, () => { this.forceUpdate(); });
	}

	onSelectLanguage = (languageKey:string) => async () =>
	{
		await Language.selectLanguage(languageKey);
	}

	renderLanguageSelection(tinyVersion:boolean)
	{
		return (
			<View style={{
				flexDirection: tinyVersion ? 'column' : 'row',
			}}>
				<Button style={{
					minWidth: 32,
					minHeight: 32
				}} onPress={this.onSelectLanguage('de')}>
					<Text style={{
						color: Language.currentLanguage?.startsWith('de') ? primaryColor : "#CCC"
					}}>DE</Text>
				</Button>

				<Button style={{
					minWidth: 32,
					minHeight: 32
				}} onPress={this.onSelectLanguage('en')}>
					<Text style={{
						color: Language.currentLanguage?.startsWith('en') ? primaryColor : "#CCC"
					}}>EN</Text>
				</Button>
			</View>
		);
	}

	renderLogo()
	{
		return (
			<Image source={require("../../assets/logo.png")} style={{width: 260, height: 48, marginTop: 25, marginBottom: 15, marginRight: 30}} />
		);
	}

	renderTitle()
	{
		return (
			<Text style={{
				flex: 1,
				fontSize: 24,
				color: '#0060af',
				fontWeight: '600'
			}}>{I18n.t("header")}</Text>
		);
	}

	render()
	{
		let paddingHorizontal:number = 30;
		const w = DeviceInfo.instance.width;
		if (w > 1180)
			paddingHorizontal += (w - 1180) * 0.5;

		let smallVersion:boolean = false;
		const startSmallVersion = 750;
		if (w <= startSmallVersion)
			smallVersion = true;
		const tinyVersion = w < 400;

		if (smallVersion)
		{
			return (
				<View style={[styles.main, {paddingHorizontal}, smallVersion ? styles.mainSmallAddition : {}]}>

					{this.renderLogo()}

					<View style={{
						position: 'absolute',
						right: tinyVersion ? 8 : 15,
						top: tinyVersion ? 14 : 20
					}}>
						{this.renderLanguageSelection(tinyVersion)}
					</View>

					<View style={{
						width: '100%',
						justifyContent: 'center',
						alignItems: 'center',
						marginBottom: 15
					}}>
						{this.renderTitle()}
						
					</View>
				</View>
			);
		}
		else
		{
			let paddingT:number = (w - startSmallVersion) / (1200 - startSmallVersion);
			return (
				<View style={[styles.main, {paddingHorizontal}]}>

					{this.renderLogo()}

					<View style={{
						flex: 1,
						flexDirection: 'row',
						justifyContent: 'space-between',
						paddingLeft: MathUtil.interpolate(30, 150, paddingT, true)
					}}>
						{this.renderTitle()}
						{this.renderLanguageSelection(false)}
					</View>
				</View>
			);
		}
	}
}

const styles = StyleSheet.create({
	main: {
		width: "100%",
		borderBottomColor: '#e3e2de',
		borderBottomWidth: 1,
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	mainSmallAddition: {
		flexDirection: 'column'
	}
});
