import TypeUtil from "./TypeUtil";

export default abstract class StyleUtil
{
	public static merge(s0:any, s1:any):any
	{
		if (!s0)
			return s1;
		if (!s1)
			return s0;
		return {...s0, ...s1};
	}

	public static convertFontWeightIntoFontFamily(style:any, fontBaseName:string = "OS"):any
	{
		let result:any = {};

		for (let key in style)
		{
			const value = style[key];

			if (key === "fontWeight")
			{
				result["fontFamily"] = fontBaseName + value;
			}
			else
			{
				result[key] = value;
			}
		}

		return result;
	}

	public static getPositioning(style:any):string
	{
		if (!style)
			return "relative";

		if (TypeUtil.isArray(style))
		{
			let result:string = "relative";
			for (let i = 0; i < style.length; ++i)
			{
				if (style[i])
				{
					const p = style[i].position;
					if (p)
						result = p;
				}
			}
			return result;
		}
		else
		{
			if (style.position === "absolute")
				return style.position;
			else
				return "relative";
		}
	}
}