import { error, log } from "./Logger";

export default abstract class Assert
{
	public static isTrue(condition:boolean, str:string = ""):void
	{
		if (!condition)
			Assert._failed(str);
	}

	public static isFalse(condition:boolean, str:string = ""):void
	{
		if (condition)
			Assert._failed(str);
	}

	public static isEqual(value:any, expected:any, str:string = ""):void
	{
		if (value != expected)
			Assert._failed("got value: [" + value + "] expected [" + expected + "] - " + str);
	}

	public static isValid(obj:any, str:string = ""):void
	{
		if ((obj == null) || (obj == undefined))
			Assert._failed(str);
	}

	public static unhandledCase(type:any, str:string = ""):never
	{
		return Assert._failed("switch case [" + type + "] not handled - " + str);
	}

	public static illegal(str:string = ""):never
	{
		return Assert._failed("illegal code path: " + str);
	}

	public static notImplemented(str:string = ""):never
	{
		return Assert._failed("not implemented: " + str);
	}

	private static _failed(str:string):never
	{
		const st = new Error().stack;
		if (str && str.length > 0)
			str += "\nCS:\n" + st;
		else
			str = "\nCS:\n" + st;

		if (str && str.length > 0)
		{
			error("ASSERTION failed: " + str);
		}
		else
		{
			error("ASSERTION failed");
		}

		try
		{
			throw new Error(str);
		}
		catch (e)
		{
			log("ASSERTION FAILED: ", e)
		}
		throw new Error("ASSERTION failed: " + (str && str.length > 0 ? str : "N/A"));
	}
}

