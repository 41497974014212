import Assert from "../debug/Assert";

export default abstract class TypeUtil
{
	public static isObject(value:any):value is object
	{
		return ((typeof value === "object") && (!Array.isArray(value)) && (value !== null));
	}

	public static isArray(value:any):boolean
	{
		return ((Array.isArray(value)) || (value instanceof Array));
	}

	public static isStringArray(value:any):value is string[]
	{
		if (!TypeUtil.isArray(value))
			return false;

		let a:any[] = value as any[];
		for (let i = 0; i < a.length; ++i)
		{
			if (!TypeUtil.isString(a[i]))
				return false;
		}

		return true;
	}

	public static isFunction(value:any):value is Function
	{
		return (typeof(value) === "function");
	}

	public static isPod(value:any):boolean
	{
		if (TypeUtil.isObject(value))
			return false;
		if (TypeUtil.isArray(value))
			return false;
		if (TypeUtil.isFunction(value))
			return false;
		return true;
	}

	public static isNumberStr(value:any):boolean
	{
		return !isNaN(value);
	}

	public static isInteger(value:any):value is number
	{
		return ((typeof value === "number") && (value % 1 === 0));
	}

	public static isFloat(value:any):value is number
	{
		return ((typeof value === "number") && (value % 1 !== 0));
	}

	public static isBoolean(value:any):value is boolean
	{
		return (typeof value === "boolean");
	}

	public static isNumber(value:any):value is number
	{
		return (typeof value === "number");
	}

	public static isString(value:any):value is string
	{
		return (typeof value === "string" || value instanceof String);
	}

	public static stringToNumber(input: string):number|undefined
	{ 
		if (!input || input.trim().length === 0)
			return undefined;

		input = input.replace(",", ".");

		var result = Number(input);
		if (isNaN(result))
			return undefined;
		return result;
	}

	public static isValidString(obj:any, str:string = ""):void
	{
		Assert.isTrue(obj && TypeUtil.isString(obj));
	}

	public static isDate(value:any):value is Date
	{
		if (!value)
			return false;
		return typeof value.getMonth === 'function';
	}

	public static isJsonString(str:string):boolean
	{
		try
		{
			JSON.parse(str);
		}
		catch (e)
		{
			return false;
		}
		return true;
	}

	public static getObjectClass(obj:any):any
	{
		if (obj && obj.$$class)
			return obj.$$class;

		if (obj && obj.constructor && obj.constructor.toString)
		{
			// internet explorer syntax: [object xxx]
			let arr = obj.constructor.toString().match(/\[object\s*(\w+)\]/);
			if (arr && arr.length === 2)
				return arr[1];

			// syntax: [object xxx]
			arr = obj.constructor.toString().match(/function\s*(\w+)/);
			if (arr && arr.length === 2)
				return arr[1];
		}

		debugger;
		Assert.illegal("getObjectClass: could not determine object class");
		return undefined;
	}

	public static clone(obj:any, deep:boolean):any
	{
		if (deep)
		{
			if (obj === null || obj === undefined)
				return obj;
			
			if (obj instanceof Date)
				return new Date(obj.getTime());
			
			// First part is for array and second part is for Realm.Collection
			if (typeof obj === 'object')
			{
				if (typeof obj[Symbol.iterator] === 'function')
				{
					let cp:any[] = [];
					if ((obj as any[]).length > 0)
					{
						for (const arrayMember of obj as any[])
						{
							cp.push(TypeUtil.clone(arrayMember, true));
						}
					}
					return cp;
				}
				else
				{
					const targetKeys = Object.keys(obj);
					let cp:any = {};
					if (targetKeys.length > 0)
					{
						for (const key of targetKeys)
						{
							cp[key] = TypeUtil.clone(obj[key], true);
						}
					}
					return cp;
				}
			}

			// Object is atomic
			return obj;
		}
		else
		{
			return Object.create(obj);
		}
	}

	public static nullToUndefined(obj:any):void
	{
		if (!obj)
			return;
			
		if (obj instanceof Date)
			return;
		
		if (typeof obj === 'object')
		{
			if (typeof obj[Symbol.iterator] === 'function') // Array
			{
				for (let i = 0; i < (obj as any[]).length; ++i)
				{
					if (obj[i] === null)
						obj[i] = undefined;
					else if (obj[i] !== undefined)
						TypeUtil.nullToUndefined(obj[i]);
				}
			}
			else
			{
				const keys = Object.keys(obj);
				for (const key of keys)
				{
					if (obj[key] === null)
						obj[key] = null;
					else if (obj[key] !== undefined)
						TypeUtil.nullToUndefined(obj[key]);
				}
			}
		}
	}

	public static equals(x:any, y:any):boolean
	{
		if ( x === y ) return true;
		// if both x and y are null or undefined and exactly the same

		if ( ! ( x instanceof Object ) || ! ( y instanceof Object ) ) return false;
			// if they are not strictly equal, they both need to be Objects

		if ( x.constructor !== y.constructor ) return false;
			// they must have the exact same prototype chain, the closest we can do is
			// test there constructor.

		for ( var p in x ) {
			if ( ! x.hasOwnProperty( p ) ) continue;
			// other properties were tested using x.constructor === y.constructor

			if ( ! y.hasOwnProperty( p ) ) return false;
			// allows to compare x[ p ] and y[ p ] when set to undefined

			if ( x[ p ] === y[ p ] ) continue;
			// if they have the same strict value or identity then they are equal

			if ( typeof( x[ p ] ) !== "object" ) return false;
			// Numbers, Strings, Functions, Booleans must be strictly equal

			if ( ! TypeUtil.equals( x[ p ],  y[ p ] ) ) return false;
			// Objects and Arrays must be tested recursively
		}

		for ( p in y ) {
			if ( y.hasOwnProperty( p ) && ! x.hasOwnProperty( p ) ) return false;
			// allows x[ p ] to be set to undefined
		}
		return true;
	}
}

