import React from 'react';
import { View, StyleSheet } from 'react-native';
import { observer, inject } from 'mobx-react';
import { AppStoreName, appStoreDefaultProps, AppStoreProps } from '../stores/AppStore';
import I18n from '../lib/i18n';
import Text from '../lib/components/Text';
import DeviceInfo from '../lib/utils/DeviceInfo';
import { Events } from '../lib/Events';
import Spinner from '../lib/components/Spinner';
import TransactionStore from '../stores/TransactionStore';
import Button from '../lib/components/Button';
import Language from '../lib/i18n/Language';
import StringUtil from '../lib/utils/StringUtil';
import Braintree from './Braintree';
import Transaction from '../models/Transaction';

export interface Props extends AppStoreProps
{
	onRestart: () => void,
	isActive:boolean
}

@inject(AppStoreName) @observer
export default class PaymentStepBraintree extends React.Component<Props>
{
	static defaultProps = appStoreDefaultProps;

	componentDidMount()
	{
		Events.listen(DeviceInfo.EVENT_SCREEN_CHANGED, this.onScreenChanged);
		Events.listen(TransactionStore.EVENT_TRANSACTION_STATUS_CHANGED, this.onTransactionStatusChanged);
		Events.listen(Language.EVENT_LANGUAGE_CHANGED, this.onLanguageChanged);
	}

	componentWillUnmount()
	{
		Events.removeListener(Language.EVENT_LANGUAGE_CHANGED, this.onLanguageChanged);
		Events.removeListener(TransactionStore.EVENT_TRANSACTION_STATUS_CHANGED, this.onTransactionStatusChanged);
		Events.removeListener(DeviceInfo.EVENT_SCREEN_CHANGED, this.onScreenChanged);
	}

	onScreenChanged = () =>
	{
		this.forceUpdate();
	}

	onLanguageChanged = () =>
	{
		this.forceUpdate();
	}

	onTransactionStatusChanged = (oldStatus:string, newStatus:string) =>
	{
		this.forceUpdate();

		if (Transaction.isCompletionStatus(newStatus) &&
			!Transaction.isCompletionStatus(oldStatus) &&
			DeviceInfo.instance.width < 800)
		{
			// Scroll to top when checkout is complete
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}

	isLoading():boolean
	{
		return false;
		// const transaction = this.props.appStore.transactionStore.transaction;
		// return !transaction.provider_id && this.props.appStore.transactionStore.transactionStatus === SkrillService.STATUS_PENDING;
	}

	isError():boolean
	{
		const transaction = this.props.appStore.transactionStore.transaction;
		return !transaction.provider_id && this.props.appStore.transactionStore.transactionStatus === Transaction.STATUS_FAILED;
	}

	onNextStep = () =>
	{
		this.props.onRestart();
	}

	render()
	{
		const transaction = this.props.appStore.transactionStore.transaction;

		const isLoading = this.isLoading();
		const isError = this.isError();
		const isProcessed = this.props.appStore.transactionStore.transactionStatus === Transaction.STATUS_PROCESSED;
		const isProcessingError = transaction.provider_id && this.props.appStore.transactionStore.transactionStatus === Transaction.STATUS_FAILED;
		const isCanceled = this.props.appStore.transactionStore.transactionStatus === Transaction.STATUS_CANCELLED;

		const w = DeviceInfo.instance.width;
		const smallVersion = w < 800;

		return (
			<View
				style={smallVersion ? styles.mainSmall : styles.main}
			>
				<View style={{
					marginBottom: 32
				}}>
					{!isProcessed && !isCanceled && !isProcessingError &&
						<View style={styles.paymentDetails}>
							<Text style={{fontWeight: "700", fontSize: 20, marginBottom: 16}}>{I18n.t("orderoverview")}</Text>
							<Text style={{marginBottom: 8, fontSize: 16}}>{I18n.t("name")}: {transaction.first_name + ' ' + transaction.last_name}</Text>
							<Text style={{marginBottom: 8, fontSize: 16}}>{I18n.t("purpose.orderoverview")}: {I18n.t("purpose.optionshort" + transaction.purpose)}</Text>
							<Text style={{marginBottom: 8, fontSize: 16}}>{I18n.t("tipwinuserid")}: {transaction.tipwin_id}</Text>
							<Text style={{marginBottom: 8, fontSize: 16}}>{I18n.t("amount")}: {StringUtil.currencyToStr(transaction.amount) + ' EUR'}</Text>
						</View>}

					{isProcessed &&
						<View style={styles.nextStep}>
							<Text style={{fontWeight: "700", fontSize: 20}}>{I18n.t("orderprocessed")}</Text>
							<Button style={styles.nextStepButton} onPress={this.onNextStep}><Text style={{
								color: "#FFF",
								fontWeight: "700"
							}}>{I18n.t("buttonnextstep")}</Text></Button>
						</View>}
					{isCanceled &&
						<View style={styles.nextStep}>
							<Text style={{fontWeight: "700", fontSize: 20}}>{I18n.t("ordercanceled")}</Text>
							<Button style={styles.nextStepButton} onPress={this.onNextStep}><Text style={{
								color: "#FFF",
								fontWeight: "700"
							}}>{I18n.t("buttonrestart")}</Text></Button>
						</View>}
					{isProcessingError &&
						<View style={styles.nextStep}>
							<Text style={{fontWeight: "700", fontSize: 20}}>{I18n.t("errorpreparecheckout")}</Text>
							<Button style={styles.nextStepButton} onPress={this.onNextStep}><Text style={{
								color: "#FFF",
								fontWeight: "700"
							}}>{I18n.t("buttonrestart")}</Text></Button>
						</View>}

				</View>

				<View style={{
					width: Math.min(w, 500),
					flexDirection: 'column',
					justifyContent: 'flex-start',
					alignItems: 'center',
				}}>
					{isLoading &&
						<Spinner />}

					{isError &&
						<Text style={{fontWeight: "700", color: "red", fontSize: 16, marginBottom: 48, marginLeft: 32}}>{I18n.t('errorpreparecheckout')}</Text>}

					{!isLoading && 
						<Braintree interactive={!isError && !isProcessed} error={isError} />}
				</View>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	main: {
		flex: 1,
		flexDirection: 'row'
	},
	mainSmall: {
		flex: 1,
		flexDirection: 'column',
		alignItems: 'center'
	},
	paymentDetails: {
	},
	nextStep: {
	},
	nextStepButton: {
		marginTop: 48,
		marginBottom: 48,
		backgroundColor: "rgb(33, 150, 83)"
	},
	nextStepButtonLabel: {
		color: "#FFF"
	}
});