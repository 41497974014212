import {decode as atob, encode as btoa} from 'base-64';

export default abstract class StringUtil
{
	public static replaceAll(str:string, search:string, replacement:string):string
	{
		if (!str)
			return str;
		return str.split(search).join(replacement);
	}

	public static trimToLength(str:string, maxLength:number, elipsis:string|undefined = undefined):string
	{
		if (str.length > maxLength)
		{
			if (elipsis && elipsis.length > 0)
			{
				str = str.substring(0, maxLength - elipsis.length);
				str += elipsis;
			}
			else
			{
				str = str.substring(0, maxLength);
			}
		}
		return str;
	}

	public static randomStr(length:number, alphabet:string|undefined = undefined):string
	{
		let text:string = "";
		let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		if (alphabet !== undefined)
			possible = alphabet;
	  
		for (let i = 0; i < length; i++)
		  text += possible.charAt(Math.floor(Math.random() * possible.length));
	  
		return text;
	}

	public static base64EncodeUnicode(str:string):string
	{
		// first we use encodeURIComponent to get percent-encoded UTF-8,
		// then we convert the percent encodings into raw bytes which
		// can be fed into btoa.
		return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
			function toSolidBytes(match, p1) {
				return String.fromCharCode(Number('0x' + p1));
		}));
	}

	public static base64DecodeUnicode(str:string):string
	{
		// Going backwards: from bytestream, to percent-encoding, to original string.
		return decodeURIComponent(atob(str).split('').map(function(c) {
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(''));
	}

	public static base64Encode(str:string):string
	{
		// first we use encodeURIComponent to get percent-encoded UTF-8,
		// then we convert the percent encodings into raw bytes which
		// can be fed into btoa.
		return btoa(str);
	}

	public static base64Decode(str:string):string
	{
		// Going backwards: from bytestream, to percent-encoding, to original string.
		return atob(str);
	}

	public static encodeUtf8(s:string):string
	{
		return unescape(encodeURIComponent(s));
	}
	  
	public static decodeUtf8(s:string):string
	{
		return decodeURIComponent(escape(s));
	}

	public static createGuid():string
	{
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
	}

	public static cutStr(str:string, cutStart:number, cutEnd:number):string
	{
		return str.substr(0, cutStart) + str.substr(cutEnd + 1);
	}

	public static isValidEmail(email:string):boolean
	{
		if (!email)
			return false;
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	public static countAndText(count:number, singularText:string, pluralText:string):string
	{
		if (count === 1)
			return count + " " + singularText;
		else
			return count + " " + pluralText;
	}

	public static numMatchingCharsAtStart(a:string, b:string):number
	{
		if (!a || !b)
			return 0;

		let result = 0;
		for (let i = 0; i < a.length && i < b.length; ++i)
		{
			if (a[i] !== b[i])
				break;

			++result;
		}

		return result;
	}

	public static onlyDigits(str:string):boolean
	{
		return /^\d+$/.test(str);
	}

	public static createStrOfLength(length:number, c:string):string
	{
		let result:string = "";
		for (let i:number = 0; i < length; ++i)
			result += c;
		return result;
	}

	public static fillUpToLength(start:string, length:number, c:string):string
	{
		let result:string = start;
		while (result.length < length)
			result += c;
		return result;
	}

	public static currencyToStr(amount:number):string
	{
		return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&");
	}
}