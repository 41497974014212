import React from 'react';
import { View, StyleSheet } from 'react-native';
import { observer, inject } from 'mobx-react';
import { AppStoreName, appStoreDefaultProps, AppStoreProps } from '../stores/AppStore';
import Text from '../lib/components/Text';
import CircledText from '../lib/components/CircledText';
import { primaryColor } from '../config/Color';

export interface Props extends AppStoreProps
{
	currentIndex:number,
	count:number,
	currentLabel:string,
	allDone:boolean
}

@inject(AppStoreName) @observer
export default class PaymentStepsHeader extends React.Component<Props>
{
	static defaultProps = appStoreDefaultProps;

	render()
	{
		let indices:number[] = [];
		for (let i:number = 0; i < this.props.count; ++i)
			indices.push(i);

		let barWidthPercent:number = Math.round(this.props.currentIndex / (this.props.count - 1) * 100);

		const doneColor:string = "#777";
		const undoneColor:string = "#DDD";

		return (
			<View
				style={styles.main}
			>
				<View style={{
					position: 'absolute',
					left: 0,
					top: 10,
					width: '100%',
					height: 4,
					backgroundColor: undoneColor
				}}/>

				<View style={{
					position: 'absolute',
					left: 0,
					top: 10,
					width: barWidthPercent.toString() + '%',
					height: 4,
					backgroundColor: primaryColor
				}}/>

				{indices.map((index) => {
					return (
						<View
							key={index}
						>
							<CircledText
								text={(index + 1).toString()}
								size={24}
								fontSizeScale={1.2}
								backgroundColor={this.props.allDone || index < this.props.currentIndex ? primaryColor :
									(index === this.props.currentIndex ? doneColor : undoneColor)}
								color="#FFF"
							>
							</CircledText>

							{index === this.props.currentIndex &&
								<View style={{
									position: 'absolute',
									top: 40,
									left: index === 0 ? 0 :
										(index === indices.length - 1 ? -56 : -40)
								}}>
									<Text numberOfLines={1} style={{fontSize: 16, color: "#555", fontWeight: "600"}}>{this.props.currentLabel}</Text>
								</View>}
						</View>
					);
				})}

			</View>
		);
	}
}

const styles = StyleSheet.create({
	main: {
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
});