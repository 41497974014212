import { Platform, PixelRatio, Dimensions, StatusBar } from 'react-native';
import Constants from 'expo-constants';
import * as Device from 'expo-device';
import { Events } from '../Events';
//import JsProfiler from './JsProfiler';

//JsProfiler.checkPoint("DeviceInfo.require");

export default class DeviceInfo
{
	public static readonly EVENT_SCREEN_CHANGED:string = "screenChanged";

	public phone:boolean;
	public tablet:boolean;
	public os:string;
	public deviceName:string|undefined;
	public manufacturer:string|undefined;
	public model:string|undefined;
	public totalMemory:number;
	public width:number;
	public height:number;
	public pixelDensity:number;
	public adjustedWidth:number;
	public adjustedHeight:number;
	public landscape:boolean;
	public osVersion:string|number;
	public ios:boolean;
	public ios10:boolean = false;
	public iphonex:boolean;
	public android:boolean;
	public emulator:boolean;
	public userAgent:string|undefined;
	public isBigScreen:boolean;
	public orientation:string;
	public packageName:string;
	public web:boolean;

	private static _instance:DeviceInfo|undefined;

	constructor()
	{
		this.os = Platform.OS;

		this.web = Platform.OS === "web"

		if (this.web)
		{
			this.ios = false;
			this.android = false;
		}
		else
		{
			this.ios = this.os.indexOf('ios') >= 0;
			this.android = !this.ios;
		}

		this.deviceName = Constants.deviceName;

		const windowSize = Dimensions.get('window');
		this.width = Math.round(windowSize.width);
		this.height = Math.round(windowSize.height);

		//if (this.android)
		//	this.height += this.statusbarHeight;

		this.pixelDensity = PixelRatio.get();

		this.adjustedWidth = Math.round(this.width * this.pixelDensity);
		this.adjustedHeight = Math.round(this.height * this.pixelDensity);

		this.landscape = this.width > this.height;

		if (this.pixelDensity < 2 && (this.adjustedWidth >= 1000 || this.adjustedHeight >= 1000))
		{
			this.tablet = true;
			this.phone = false;
		}
		else if (this.pixelDensity === 2 && (this.adjustedWidth >= 1920 || this.adjustedHeight >= 1920))
		{
			this.tablet = true;
			this.phone = false;
		}
		else
		{
			this.tablet = false;
			this.phone = true;
		}

		this.iphonex = (this.ios && !Platform.isTV &&
			(windowSize.height === 812 || windowSize.width === 812 ||
			windowSize.height === 896 || windowSize.width === 896));

		this.emulator = !Constants.isDevice;

		if (this.ios && Constants.platform && Constants.platform.ios)
			this.osVersion = Constants.platform.ios.systemVersion; // e.g. 10.2
		else
			this.osVersion = Platform.Version; // the API version

		this.manufacturer = Device.manufacturer || undefined;
		this.model = Device.modelName || undefined;
		this.totalMemory = Device.totalMemory || 0;

		this.isBigScreen = (this.width > 800 || this.height > 800) && this.width > 600 && this.height > 600;
		this.orientation = this.width > this.height ? "landscape" : "portrait";

		if (this.ios)
		{
			const majorVersionIOS = parseInt(Platform.Version.toString(), 10);
			if (majorVersionIOS <= 10)
			{
				this.ios10 = true;
			}
		}

		if (this.web)
			this.packageName = "website";
		else if (__DEV__)
			this.packageName = "host.exp.exponent";
		else
			this.packageName = this.ios ? Constants.manifest.ios.bundleIdentifier : Constants.manifest.android.package;
		//console.log(this.packageName);
	}

	public async initAsync():Promise<void>
	{
		const ua = await Constants.getWebViewUserAgentAsync();
		if (ua)
			this.userAgent = ua;
	}

	public get statusbarHeight():number
	{
		if (this.iphonex)
			return 44;
		else if (this.ios)
			return 20;
		else if (StatusBar.currentHeight === undefined)
			return 0;
		else
			return StatusBar.currentHeight;
	}

	public get bottomInset():number
	{
		return this.iphonex ? 34 : 0;
	}

	public get usableHeight():number
	{
		return this.height - this.statusbarHeight;
	}

	public setInsets(insets:any):void
	{
		//this.bottomBarHeight = insets.bottom;
		// console.log("screen: " + this.height);
		// console.log("statusbar: " + this.statusbarHeight);
		// console.log("inset: ", insets);
	}

	public onScreenMeasured(width:number, height:number):void
	{
		if (this.height !== height || this.width !== width)
		{
			if (this.android && this.width === width && height < (this.height - 200))
				return;

			//console.log("new screen: " + this.width + "x" + this.height + " -> " + width + "x" + height);

			this.width = width;
			this.height = height;
			this.adjustedWidth = Math.round(this.width * this.pixelDensity);
			this.adjustedHeight = Math.round(this.height * this.pixelDensity);
			this.landscape = this.width > this.height;

			Events.fire(DeviceInfo.EVENT_SCREEN_CHANGED, this.width, this.height);
		}
	}

	public static get instance():DeviceInfo
	{
		if (!DeviceInfo._instance)
		{
			DeviceInfo._instance = new DeviceInfo();
		}
		return DeviceInfo._instance;
	}
}
