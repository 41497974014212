import { observable, observe, reaction, runInAction } from "mobx";
import AppLoadingStore from "./AppLoadingStore";
import Language from "../lib/i18n/Language";
import TransactionStore from "./TransactionStore";

export default class AppStore
{
	@observable isLoading:boolean = true;
	@observable currentLanguage:string|undefined;

	// Sub-stores
	appLoadingStore:AppLoadingStore;
	transactionStore:TransactionStore;

	constructor()
	{
		this.appLoadingStore = new AppLoadingStore(this.onLoadingDone);
		this.transactionStore = new TransactionStore();
	}

	public async loading():Promise<void>
	{
	}

	private onLoadingDone = async () =>
	{
		reaction(
			() => Language.currentLanguage,
			() => this.currentLanguage = Language.currentLanguage
		);
	}

	public async onAfterRootMounted():Promise<void>
	{
		this.transactionStore.onAfterRootMounted();
	}
}

export type AppStoreProps = {appStore: AppStore, navigation:any};
export const appStoreDefaultProps = {appStore: (null as unknown) as AppStore, navigation: undefined};
export const AppStoreName:string = "appStore";