import React from 'react';
import { observer, inject } from 'mobx-react';
import { View, StyleSheet } from 'react-native';
import AppStore, { AppStoreName, AppStoreProps } from '../stores/AppStore';
import Language from '../lib/i18n/Language';
import I18n from '../lib/i18n';
import { Events } from '../lib/Events';
import DeviceInfo from '../lib/utils/DeviceInfo';
import { CheckoutResult } from '../stores/TransactionStore';

export interface Props extends AppStoreProps
{
	currency:string,
	interactive:boolean,
	error:boolean
}

@inject(AppStoreName) @observer
export default class Braintree extends React.Component<Props>
{
	static defaultProps = {
		appStore: (null as unknown) as AppStore,
		navigation: undefined,
		currency: "EUR",
		interactive: true,
		error: false
	};

	private static PAYMENT_FORM_ID:string = 'payment-form';
	private static SUBMITBUTTON_ID:string = 'braintreesubmitbutton';
	private static SPINNER_ID:string = 'checkoutloader';
	private static FORM_MARGIN_TOP:number = -24;
	private static WINDOW_CHECKOUT_EVENT_NAME:string = "braintree_checkout_event";

	private isUiReady:boolean = false;
	private dropinInstance:any;
	private isInteractive:boolean = true;

	getDocument():any
	{
		return document;
	}

	getWindow():any
	{
		return window;
	}

	getBraintree():any
	{
		return braintree;
	}

	async componentDidMount()
	{
		Events.listen(Language.EVENT_LANGUAGE_CHANGED, this.onLanguageChanged);
		this.getWindow().addEventListener("message", this.onWindowMessage);

		const clientToken = await this.props.appStore.transactionStore.braintreeGenerateClientToken();
		if (clientToken)
		{
			const currency = this.props.currency;
			const amount = this.props.appStore.transactionStore.transaction.amount;
			const languageCode = Language.currentLanguage?.startsWith('de') ? 'de_DE' : 'en_IN';

			// braintree.setup('CLIENT_TOKEN_FROM_SERVER', 'dropin', {
			// onReady: function (integration) {
			// 	checkout = integration;
			// }
			// });

			// // When you are ready to tear down your integration
			// checkout.teardown(function () {
			// checkout = null;
			// // braintree.setup can safely be run again!
			// });

			//(function()
			//{
				const form = this.getDocument().getElementById(Braintree.PAYMENT_FORM_ID);

				this.getBraintree().client.create({
					authorization: clientToken,
					}, (err, clientInstance) =>
					{
						// Creation of any other components...
						
						this.getBraintree().dataCollector.create({
							client: clientInstance,
							paypal: true
						}, (err, dataCollectorInstance) =>
						{
							if (err) {
								// Handle error in creation of data collector
								return;
							}
							this.getWindow().paymentDeviceData = dataCollectorInstance.deviceData;
						});
					});

				this.getBraintree().dropin.create({
					authorization: clientToken,
					container: '#dropin-container',
					locale: languageCode,
					paypal: {
						flow: 'checkout',
						amount: amount,
						currency: currency,
						buttonStyle: {
							color: 'blue',
							shape: 'rect',
							size: 'medium',
							label: 'pay'
						},
					},
					card: {
						cardholderName: {
							required: true
						}
					}
				}, (error, dropinInstance) =>
				{
					console.log("DROPIN CREATE DONE");
					this.isUiReady = true;
					//this.setSubmitButtonVisible(true);
					//this.forceUpdate();
					this.dropinInstance = dropinInstance;

					dropinInstance.on('paymentMethodRequestable', (event) =>
					{
						console.log(event.type); // The type of Payment Method, e.g 'CreditCard', 'PayPalAccount'.
						console.log(event.paymentMethodIsSelected); // True if a customer has selected a payment method when paymentMethodRequestable fires.
						
						this.setSubmitButtonVisible(true);
						//submitButton.removeAttribute('disabled');
					});

					dropinInstance.on('noPaymentMethodRequestable', () =>
					{
						this.setSubmitButtonVisible(false);
						//submitButton.setAttribute('disabled', true);
					});

					if (error)
						console.error(error);
				
					form.addEventListener('submit', event =>
					{
						event.preventDefault();
					
						dropinInstance.requestPaymentMethod((error, payload) =>
						{
							if (error) console.error(error);
					
							// Step four: when the user is ready to complete their
							//   transaction, use the dropinInstance to get a payment
							//   method nonce for the user's selected payment method, then add
							//   it as the hidden field before submitting the complete form to
							//   a server-side integration
							//this.getDocument()getElementById('nonce').value = payload.nonce;
							//this.getWindow().paymentNonce = payload.nonce;
							//this.clearLog();
							//this.addLog(this.getWindow().paymentDeviceData);
							if (this.getWindow().paymentDeviceData)
							{
								//this.addLog('POST msg: ' + payload.nonce);

								this.getWindow().postMessage(JSON.stringify({
									id: Braintree.WINDOW_CHECKOUT_EVENT_NAME,
									nonce: payload.nonce,
									deviceData: this.getWindow().paymentDeviceData
								}), "*");
							}
							//form.submit();
							//TODO: send using ajax
						});
					});
				});
			//})();
		}

		this.isInteractive = this.props.interactive;
		if (!this.props.interactive)
			this.setInteractive(false);
		this.setSubmitButtonVisible(false);
	}

	componentDidUpdate(prevProps)
	{
		if (this.props.interactive !== prevProps.interactive)
		{
			this.isInteractive = this.props.interactive;
			if (this.props.interactive)
				this.setInteractive(true);
			else
				this.setInteractive(false);
		}

		if (this.props.error !== prevProps.error)
		{
			if (this.props.error)
			{
				this.setInteractive(true);
				this.setSubmitButtonVisible(false);
				//this.setOtherPaymentMethodButtonError(true);

				if (this.dropinInstance)
				{
					this.dropinInstance.clearSelectedPaymentMethod();
				}
			}
		}
	}
	
	componentWillUnmount()
	{
		this.getWindow().removeEventListener("message", this.onWindowMessage);

		if (this.dropinInstance)
		{
			this.dropinInstance.teardown(() => {});
			this.dropinInstance = null;
		}

		Events.removeListener(Language.EVENT_LANGUAGE_CHANGED, this.onLanguageChanged);
	}

	onLanguageChanged = () =>
	{
		this.setSubmitButtonLabel('paymentsubmit');
	}

	onWindowMessage = (event) =>
	{
		//this.addLog('e: ' + JSON.stringify(event));
		if (event && event.data)
		{
			//this.addLog('de: ' + event.data);

			let data = event.data;
			try
			{
				data = JSON.parse(data);
				if (data.id === Braintree.WINDOW_CHECKOUT_EVENT_NAME && data.nonce && data.deviceData)
				{
					//this.addLog('b: ' + data);
					this.onStartCheckout(data.nonce, data.deviceData);
				}
			}
			catch (e)
			{}
		}
	}
	
	async onStartCheckout(nonce:string, deviceData: string)
	{
		this.setSubmitButtonEnabled(false);
		this.setSubmitButtonLabel('paymentwip');
		this.setSpinnerVisible(true);
		this.setOtherPaymentMethodButtonEnabled(false);
		let checkoutResult:CheckoutResult|undefined = undefined;
		try
		{
			checkoutResult = await this.props.appStore.transactionStore.braintreeCheckout(nonce, deviceData);
		}
		finally
		{
			this.setSpinnerVisible(false);
			this.setSubmitButtonLabel('paymentsubmit');
			this.setSubmitButtonEnabled(true);

			if (!checkoutResult || !checkoutResult.success)
				this.setOtherPaymentMethodButtonEnabled(true);
		}
	}

	setSubmitButtonVisible(flag:boolean)
	{
		const btn:any = this.getDocument().getElementById(Braintree.SUBMITBUTTON_ID);
		if (!btn)
			return;

		btn.style.visibility = flag ? 'visible' : 'hidden';
	}

	setSpinnerVisible(flag:boolean)
	{
		const element:any = this.getDocument().getElementById(Braintree.SPINNER_ID);
		if (!element)
			return;

		element.style.visibility = flag ? 'visible' : 'hidden';
	}

	setSubmitButtonEnabled(flag:boolean)
	{
		const btn:any = this.getDocument().getElementById(Braintree.SUBMITBUTTON_ID);
		if (!btn)
			return;

		if (flag)
		{
			btn.removeAttribute('disabled');
			btn.style.backgroundColor = 'rgb(0, 96, 175)';
		}
		else
		{
			btn.setAttribute('disabled', true);
			btn.style.backgroundColor = 'rgb(238, 238, 238)';
		}
	}

	setSubmitButtonLabel(translationKey:string)
	{
		const btn:any = this.getDocument().getElementById(Braintree.SUBMITBUTTON_ID);
		if (!btn)
			return;
		btn.value = I18n.t(translationKey);
	}

	setInteractive(flag:boolean)
	{
		this.isInteractive = flag;

		this.setSubmitButtonVisible(flag);
		if (!flag)
			this.setSpinnerVisible(false);

		// var header = this.getDocument().querySelector("[data-braintree-id=choose-a-way-to-pay]");
		// if (header)
		// 	header.style.display = flag ? 'block' : 'none';

		var label = this.getDocument().querySelector("[data-braintree-id=methods-label]");
		if (label)
			//label.style.display = flag ? 'block' : 'none';
			label.style.color = flag ? 'rgb(0, 96, 175)' : '#EEE';

		const form = this.getDocument().getElementById(Braintree.PAYMENT_FORM_ID);
		if (form)
		{
			if (flag)
				form.style.marginTop = Braintree.FORM_MARGIN_TOP;
			else if (DeviceInfo.instance.width < 800)
				form.style.marginTop = 24;
			else
				form.style.marginTop = 0;
		}

		this.setOtherPaymentMethodButtonEnabled(flag);
	}

	setOtherPaymentMethodButtonEnabled(flag:boolean)
	{
		var toggle = this.getDocument().querySelector("[data-braintree-id=toggle]");
		if (toggle)
		{
			if (flag)
			{
				toggle.style.cursor = 'cursor';
				toggle.style.pointerEvents = 'all';
				toggle.style.color = '#000';
			}
			else
			{
				toggle.style.cursor = 'default';
				toggle.style.pointerEvents = 'none';
				toggle.style.color = '#eee';
			}
		}
		var toggleSpan = this.getDocument().querySelector("[data-braintree-id=toggle] span");
		if (toggleSpan)
		{
			toggleSpan.style.borderBottom = flag ? '1px solid #b5b5b5' : '1px solid transparent';
		}
	}

	// setOtherPaymentMethodButtonError(flag:boolean)
	// {
	// 	var toggle = this.getDocument().querySelector("[data-braintree-id=toggle]");
	// 	if (toggle)
	// 	{
	// 		toggle.style.color = flag ? '#fff' : '#000';
	// 		toggle.style.backgroundColor = flag ? 'red' : '#fafafa';
	// 	}
	// 	var toggleSpan = this.getDocument().querySelector("[data-braintree-id=toggle] span");
	// 	if (toggleSpan)
	// 	{
	// 		toggleSpan.style.borderBottom = flag ? '1px solid #fff' : '1px solid #b5b5b5';
	// 	}
	// }

	// addLog(msg:string)
	// {
	// 	const btn:any = this.getDocument().getElementById('log');
	// 	btn.value += "\r\n\r\n" + msg;
	// }

	// clearLog()
	// {
	// 	const btn:any = this.getDocument().getElementById('log');
	// 	btn.value = "";
	// }

	render()
	{
		const submitUrl = this.props.appStore.transactionStore.getBraintreeCheckoutSubmitUrl();

		const label = I18n.t('paymentsubmit');

		let formStyle:string = "margin-top:" + Braintree.FORM_MARGIN_TOP + "px;";
		if (DeviceInfo.instance.width < 800)
		{
			const padding = 32;
			formStyle += "max-width:" + Math.round(DeviceInfo.instance.width - 2 * padding) + "px;";
		}

		var htmlCode:string =
			"<form id=\"" + Braintree.PAYMENT_FORM_ID + "\" action=\"" + submitUrl + "\" method=\"POST\" autocomplete=\"off\" style=\"" + formStyle + "\">" +
			"	<div id=\"dropin-container\"></div>" +
			"	<span style=\"position:relative;\">" +
			"		<input id=\"" + Braintree.SUBMITBUTTON_ID + "\" value=\"" + label + "\" type=\"submit\" style=\"visibility:hidden; font-size:14px;font-family:OS700;font-weight:400;color:rgb(255,255,255);" +
			"			padding-left:24px;padding-right:24px;margin-top:24px;background-color: rgb(0, 96, 175); cursor: pointer; border: 0px solid black; border-radius: 4px; min-height: 40px; min-width: 80px;" +
						"-webkit-appearance: none;-moz-appearance: none;appearance: none;\" />" + // fix CSS for iOS
			"		<div class=\"checkoutloader\" id=\"" + Braintree.SPINNER_ID + "\" style=\"visibility:hidden;\"></div>" +
			"	</span>" +
			// comment in for onscreen log messages: " <textarea rows=\"20\" cols=\"50\" id=\"log\" ></textarea>" +
			  "</form>";
			  
		// if (!this.isInteractive)
		// {
		// 	// we can't change the html code above at runtime, otherwise the Braintree UI would be destroyed =>
		// 	// patch styling using JS
		// 	setTimeout(() =>
		// 	{
		// 		this.setInteractive(this.isInteractive);
		// 	}, 300);
		// }
			
		return (
			<View>
				<div dangerouslySetInnerHTML={{ __html: htmlCode }} />
			</View>
		);
	}
}


const styles = StyleSheet.create({
	main: {
	},
});