import React from 'react';
import { observer } from 'mobx-react';
import { View, Animated, Easing, TouchableWithoutFeedback, StyleSheet, TouchableWithoutFeedbackProps } from 'react-native';
import { observable, action } from 'mobx';
import Assert from '../debug/Assert';
import DeviceInfo from '../utils/DeviceInfo';
import { TouchableWithoutFeedback as GHTouchableWithoutFeedback } from 'react-native-gesture-handler';

export interface Props extends TouchableWithoutFeedbackProps
{
	rippleColor?:string,
    rippleOpacity?:number,
    rippleDuration?:number,
    rippleSize?:number,
    rippleContainerBorderRadius?:number,
    rippleCentered?:boolean,
    rippleSequential?:boolean,
    rippleFades?:boolean,
	disabled?:boolean,
	forceNativeTouchable:boolean,
    onRippleAnimation?: (animation:any, onAnimationEnd: () => void) => void,
}

interface IRipple
{
	nextId:number,
	progress:Animated.Value,
	locationX:number,
	locationY:number,
	R:number,
}

@observer
export default class Button extends React.Component<Props>
{
	public static readonly START_RADIUS:number = 10;

	private _isMounted:boolean = false;
	private nextId:number = 0;

	@observable width:number|undefined;
	@observable height:number|undefined;
	@observable ripples:IRipple[] = [];
	private onPressEnabled:boolean = true;

	static defaultProps:Props = {
        rippleColor: 'rgb(0, 0, 0)',
		rippleOpacity: 0.30,
		rippleDuration: 400,
		rippleSize: 0,
		rippleContainerBorderRadius: 4,
		rippleCentered: false,
		rippleSequential: false,
		rippleFades: true,
		disabled: false,
		forceNativeTouchable: false,
		onRippleAnimation: (animation, callback) => animation.start(callback),
	}

	componentDidMount()
	{
		this._isMounted = true;
	}
	
	componentWillUnmount()
	{
		this._isMounted = false;
	}

	@action
	onLayout = (event) =>
	{
		if (this.props.onLayout)
		{
			this.props.onLayout(event);
		}
	
		this.width = event.nativeEvent.layout.width;
		this.height = event.nativeEvent.layout.height;
	}

	onPress = (event) =>
	{
		let { rippleSequential } = this.props;

		if (!this.onPressEnabled)
			return;
	
		if (!rippleSequential || this.ripples.length === 0)
		{
			if (this.props.onPress)
			{
				requestAnimationFrame(() => {
					if (this.props.onPress)
						this.props.onPress(event);
				});
			}

			// Prevent super-fast onPress events
			this.onPressEnabled = false;
			setTimeout(() => {
				this.onPressEnabled = true;
			}, 250);
	
			this.startRipple(event);
		}
	}

	onLongPress = (event) =>
	{
		if (this.props.onLongPress)
		{
			requestAnimationFrame(() => {
				if (this.props.onLongPress)
					this.props.onLongPress(event);
			});
		}
	
		this.startRipple(event);
	}
	
	onPressIn = (event) =>
	{
		if (this.props.onPressIn)
			this.props.onPressIn(event);
	}

	onPressOut = (event) =>
	{
		if (this.props.onPressOut)
			this.props.onPressOut(event);
	}
	
	@action
	onAnimationEnd = () =>
	{
		if (this._isMounted)
		{
			Assert.isTrue(this.ripples.length > 0);
			this.ripples.splice(0, 1);
		}
	}
	
	@action
	startRipple(event)
	{
		if (this.width === undefined || this.height === undefined)
			return;
		if (this.props.disabled === true)
			return;

		let {
			rippleDuration,
			rippleCentered,
			rippleSize,
			onRippleAnimation,
		} = this.props;
	
		const w2 = 0.5 * this.width;
		const h2 = 0.5 * this.height;
	
		let locationX;
		let locationY;
		if (rippleCentered)
		{
			locationX = w2;
			locationY = h2;
		}
		else if (event && event.nativeEvent)
		{
			locationX = event.nativeEvent.locationX;
			locationY = event.nativeEvent.locationY;
		}
		else
		{
			locationX = w2;
			locationY = h2;
		}
	
		const offsetX = Math.abs(w2 - locationX);
		const offsetY = Math.abs(h2 - locationY);
	
		const R = rippleSize! > 0 ? 0.5 * rippleSize! : Math.sqrt(Math.pow(w2 + offsetX, 2) + Math.pow(h2 + offsetY, 2));
	
		const ripple = {
			nextId: this.nextId++,
			progress: new Animated.Value(0),
			locationX,
			locationY,
			R,
		};
	
		let animation = Animated.timing(ripple.progress, {
			toValue: 1,
			easing: Easing.out(Easing.ease),
			duration: rippleDuration,
			useNativeDriver: !DeviceInfo.instance.web,
		});
	
		onRippleAnimation!(animation, this.onAnimationEnd);
	
		this.ripples.push(ripple);
	}
	
	renderRipple = ({ nextId, progress, locationX, locationY, R }) =>
	{
		let { rippleColor, rippleOpacity, rippleFades } = this.props;
	
		let rippleStyle =
		{
			top: locationY - Button.START_RADIUS,
			left: locationX - Button.START_RADIUS,
			backgroundColor: rippleColor,
			transform: [{
				scale: progress.interpolate({
					inputRange: [0, 1],
					outputRange: [0.5 / Button.START_RADIUS, R / Button.START_RADIUS],
				}),
		  	}],
	
			opacity: rippleFades ? 
				progress.interpolate({
					inputRange: [0, 1],
					outputRange: [rippleOpacity, 0],
				}) : rippleOpacity,
		};
	
		return (
			<Animated.View style={[styles.ripple, rippleStyle]} key={nextId} />
		);
	}
	
	renderContent()
	{
		let {
			delayLongPress,
			delayPressIn,
			delayPressOut,
			disabled,
			hitSlop,
			pressRetentionOffset,
			children,
			rippleContainerBorderRadius,
			testID,
			accessible,
			accessibilityHint,
			accessibilityLabel,
			onPress,
			onLongPress,
			onLayout,
			onRippleAnimation,
			rippleColor,
			rippleOpacity,
			rippleDuration,
			rippleSize,
			rippleCentered,
			rippleSequential,
			rippleFades,
			style,
			...props
		} = this.props;
	
		return (
			<Animated.View
				style={[styles.defaultButtonStyle, style]}
				{...props}
				pointerEvents='box-only'
			>
				{children}
				<View style={[styles.container, {
					borderRadius: rippleContainerBorderRadius,
				}]}>
					{this.ripples.map(this.renderRipple)}
				</View>
			</Animated.View>
		);
	}

	render()
	{
		let {
			delayLongPress,
			delayPressIn,
			delayPressOut,
			disabled,
			hitSlop,
			pressRetentionOffset,
			children,
			rippleContainerBorderRadius,
			testID,
			accessible,
			accessibilityHint,
			accessibilityLabel,
			onPress,
			onLongPress,
			onLayout,
			onRippleAnimation,
			rippleColor,
			rippleOpacity,
			rippleDuration,
			rippleSize,
			rippleCentered,
			rippleSequential,
			rippleFades,
			style,
			...props
		} = this.props;

		let touchableProps = {
			delayLongPress,
			delayPressIn,
			delayPressOut,
			disabled,
			hitSlop,
			pressRetentionOffset,
			testID,
			accessible,
			accessibilityHint,
			accessibilityLabel,
			onLayout: this.onLayout,
			onPress: this.onPress,
			onPressIn: this.onPressIn,
			onPressOut: this.onPressOut,
			onLongPress: onLongPress ? this.onLongPress : undefined,
		};

		// Bug in GHTouchableOpacity: "absolute" positioned elements don't show up
		//Assert.isTrue(StyleUtil.getPositioning(style) === "relative", "Absolute positioned buttons won't work on Android");

		// There is a bug with bottomsheet on Android that the common Touchable-Components from react-native don't work
		// => use the components from react-native-gesture-handler instead
		// https://github.com/osdnk/react-native-reanimated-bottom-sheet/issues/16

		if (DeviceInfo.instance.ios || this.props.forceNativeTouchable)
		{
			return (
				<TouchableWithoutFeedback {...touchableProps}>
					{this.renderContent()}
				</TouchableWithoutFeedback>
			);
		}
		else
		{
			return (
				<GHTouchableWithoutFeedback
					{...touchableProps}
				>
					{this.renderContent()}
				</GHTouchableWithoutFeedback>
			);
		}
	}
}

const styles = StyleSheet.create({
	container: {
		...StyleSheet.absoluteFillObject,
		backgroundColor: 'transparent',
		overflow: 'hidden',
	},
	defaultButtonStyle: {
		minHeight: 40,
		minWidth: 64,
		backgroundColor: "white",
		borderRadius: 4,
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center"
	},
	ripple: {
		width: Button.START_RADIUS * 2,
		height: Button.START_RADIUS * 2,
		borderRadius: Button.START_RADIUS,
		overflow: 'hidden',
		position: 'absolute',
	},
});