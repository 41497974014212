import React from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet, Animated, Easing, MeasureInWindowOnSuccessCallback } from 'react-native';
import I18n from '../i18n';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

export interface Props
{
	onChangeText?:(text:string) => void,
	onInit?:(inputField:InputField) => void,
	onEnter?:() => void,
	onFocus?:(e:any) => void,
	onBlur?:(e:any) => void,
	editable:boolean,
	labelText:string,
	labelTextSize:number,
	labelColor:string,
	textColor:string,
	borderBottomColor:string,
	inputType:string,
	customStyle:any,
	showCheckmark:boolean,
	autoFocus:boolean,
	autoCapitalize?:"none" | "sentences" | "words" | "characters",
	labelTextWeight:string,
	inputStyle:any,
	placeholder?:string,
	defaultValue?:string,
	selectionColor:string|undefined,
	maxLength?: number,
	errorLabel?:string,
	errorLabelStyle?:any,
	numberOfLinesLabel?:number
}

export default class InputField extends React.Component<Props>
{
	static defaultProps = {
		labelTextSize: 14,
		labelTextWeight: '700',
		labelColor: "white",
		textColor: "white",
		borderBottomColor: 'transparent',
		inputStyle: {},
		customStyle: {},
		showCheckmark: false,
		autoFocus: false,
		editable: true,
		selectionColor: undefined
	};

	state:any;
	private textInput:TextInput|null = null;

	constructor(props:Props)
	{
		super(props);
		this.state = {
			secureInput: props.inputType === 'password',
			scaleCheckmarkValue: new Animated.Value(0),
			inputValue: props.defaultValue,
		};
		this.toggleShowPassword = this.toggleShowPassword.bind(this);
		this.onChangeText = this.onChangeText.bind(this);
	}

	componentDidMount()
	{
		if (this.props.onInit)
			this.props.onInit(this);
	}

	scaleCheckmark(value:number)
	{
		Animated.timing(this.state.scaleCheckmarkValue,
		{
			toValue: value,
			duration: 400,
			easing: Easing.out(Easing.ease),
			useNativeDriver: false,
		},
		).start();
	}

	toggleShowPassword()
	{
		this.setState({ secureInput: !this.state.secureInput });
	}

	onChangeText(text:string)
	{
		if (this.props.onChangeText)
			this.props.onChangeText(text);
		this.setState({ inputValue: text });
	}

	isEmpty():boolean
	{
		return this.state.inputValue === undefined || this.state.inputValue.trim().length === 0;
	}

	focus()
	{
		if (this.textInput)
			this.textInput.focus();
	}

	isFocused()
	{
		if (this.textInput)
			return this.textInput.isFocused();
		else
			return false;
	}

	blur()
	{
		if (this.textInput)
			this.textInput.blur();
	}

	clear()
	{
		this.value = "";
	}

	public set value(val:string)
	{
		this.onChangeText(val);
	}

	public get value():string
	{
		return this.state.inputValue;
	}

	public measureInWindow(callback:MeasureInWindowOnSuccessCallback):void
	{
		if (this.textInput)
			this.textInput.measureInWindow(callback);
	}

	render()
	{
		const {
			labelText,
			labelTextSize,
			labelTextWeight,
			labelColor,
			textColor,
			borderBottomColor,
			inputType,
			customStyle,
			inputStyle,
			onChangeText,
			showCheckmark,
			autoFocus,
			autoCapitalize,
			placeholder,
			defaultValue,
		} = this.props;

		const { secureInput, scaleCheckmarkValue, inputValue } = this.state;
		const fontSize = labelTextSize;
		const color = labelColor;
		const inputColor = textColor;
		const borderBottom = borderBottomColor;
		let keyboardType:any = inputType === 'email' ? 'email-address' : (inputType || 'default');
		if (keyboardType === "password" || keyboardType === "text")
			keyboardType = "default";
		const customInputStyle = inputStyle || {};
		if (!inputStyle || inputStyle && !inputStyle.paddingBottom) {
			customInputStyle.paddingBottom = 5;
		}
		customInputStyle.paddingLeft = 8;

		const iconScale = scaleCheckmarkValue.interpolate({
			inputRange: [0, 0.5, 1],
			outputRange: [0.01, 1.6, 1],
		});

		const scaleValue = showCheckmark ? 1 : 0;
		this.scaleCheckmark(scaleValue);

		return (
			<View style={[customStyle, styles.wrapper]}>
				<Text numberOfLines={this.props.numberOfLinesLabel || 1} style={[{ fontFamily: labelTextWeight === "700" ? "OS700" : "OS400", color, fontSize: labelTextSize }, styles.label]}>
					{labelText}
				</Text>

				{inputType === 'password' ? (
					<TouchableOpacity
						style={styles.showButton}
						onPress={this.toggleShowPassword}
					>
						<Text style={styles.showButtonText}>
							{secureInput ? I18n.t("passwordshow") : I18n.t("passwordhide")}
						</Text>
					</TouchableOpacity>
				)
				: null }

				<Animated.View style={[{ transform: [{ scale: iconScale }] }, styles.checkmarkWrapper]}>
					<MaterialCommunityIcons
						color={color}
						name={"check"}
						size={20}
					/>
				</Animated.View>

				<TextInput
					style={[{ color: inputColor, borderBottomColor: borderBottom }, inputStyle, styles.inputField]}
					secureTextEntry={secureInput}
					onChangeText={this.onChangeText}
					onSubmitEditing={this.props.onEnter}
					onFocus={this.props.onFocus}
					onBlur={this.props.onBlur}
					keyboardType={keyboardType}
					autoFocus={autoFocus}
					autoCapitalize={autoCapitalize}
					autoCorrect={false}
					underlineColorAndroid="transparent"
					placeholder={placeholder}
					//defaultValue={inputValue}
					value={inputValue}
					selectionColor={this.props.selectionColor}
					ref={(c) => this.textInput = c}
					editable={this.props.editable}
					maxLength={this.props.maxLength}
				/>

				{this.props.errorLabel !== undefined &&
					<View style={{
						position: 'absolute',
						top: 64
					}}>
						<Text style={this.props.errorLabelStyle}>
							{this.props.errorLabel}
						</Text>
					</View>}
			</View>
		);
	}
}

const styles = StyleSheet.create({
	wrapper: {
		display: 'flex',
	},
	label: {
		marginBottom: 8,
	},
	inputField: {
		borderBottomWidth: 1,
		paddingTop: 5,
	},
	showButton: {
		position: 'absolute',
		right: 0,
	},
	showButtonText: {
		color: "white",
		fontWeight: '700',
	},
	checkmarkWrapper: {
		position: 'absolute',
		right: 0,
		bottom: 12,
	},
});