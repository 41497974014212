import I18n from ".";
import { initMomentJs } from "./InitMomentJs";
import Assert from "../debug/Assert";
import LocalStorage from "../utils/LocalStorage";
import { log } from "../debug/Logger";
import { observable, runInAction } from 'mobx';
import { Events } from "../Events";

export default class Language
{
	public static readonly EVENT_LANGUAGE_CHANGED:string = "language";

	private static readonly STORAGE_KEY:string = "language";
	@observable static currentLanguage:string|undefined;

	public static async init():Promise<void>
	{
		const storedLanguage = await LocalStorage.get(Language.STORAGE_KEY);
		if (storedLanguage)
		{
			I18n.locale = storedLanguage;
			runInAction(() =>
			{
				Language.currentLanguage = storedLanguage;
				log("Stored language: " + Language.currentLanguage);
			});
		}
		else
		{
			await (I18n as any).initAsync();
			runInAction(() =>
			{
				//Language.currentLanguage = "de";
				//I18n.locale = Language.currentLanguage;
				Language.currentLanguage =  I18n.locale.toLowerCase();
				log("System language: " + Language.currentLanguage);
			});
		}

		initMomentJs(Language.currentLanguage!);
	}

	public static isValidLanguageKey(languageKey:string):boolean
	{
		for (let key in I18n.translations)
		{
			if (languageKey === key)
				return true;
		}
		return false;
	}

	public static async selectLanguage(languageKey:string):Promise<void>
	{
		Assert.isTrue(Language.isValidLanguageKey(languageKey));

		await LocalStorage.set(Language.STORAGE_KEY, languageKey);

		I18n.locale = languageKey;
		initMomentJs(languageKey);

		runInAction(() =>
		{
			Language.currentLanguage = languageKey;
		});

		Events.fire(Language.EVENT_LANGUAGE_CHANGED, languageKey);
	}

	public static showLanguageSelection(onDone:((languageKey:string|undefined) => void)|undefined = undefined):void
	{
		let languageNames:string[] = [];
		let languageKeys:string[] = [];
		for (let key in I18n.translations)
		{
			languageKeys.push(key);
			languageNames.push(I18n.t("languages." + key));
		}

		// ActionSheet.show({
		// 	options: [...languageNames, I18n.t("cancel")],
		// 	title: I18n.t("languages.select"),
		// 	cancelButtonIndex: languageNames.length,
		// }, (buttonIndex:number) =>
		// {
		// 	let result:string|undefined;
		// 	if (buttonIndex >= 0 && buttonIndex < languageNames.length)
		// 	{
		// 		result = languageKeys[buttonIndex];
		// 		Language.selectLanguage(languageKeys[buttonIndex]);
		// 	}

		// 	if (onDone)
		// 		onDone(result);
		// });
	}
}