import React from 'react';
import { observer, inject } from 'mobx-react';
import { AppStoreName, appStoreDefaultProps, AppStoreProps } from '../stores/AppStore';
import { StyleSheet, View } from 'react-native';
import Text from '../lib/components/Text';
import I18n from '../lib/i18n';
import { Events } from '../lib/Events';
import Language from '../lib/i18n/Language';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPhoneSquare, faFax, faEnvelope, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import DeviceInfo from '../lib/utils/DeviceInfo';
import Anchor from '../lib/components/Anchor';


export interface Props extends AppStoreProps
{
}

@inject(AppStoreName) @observer
export default class PageFooter extends React.Component<Props>
{
	static defaultProps = appStoreDefaultProps;

	componentDidMount()
	{
		Events.listen(Language.EVENT_LANGUAGE_CHANGED, () => { this.forceUpdate(); });
		Events.listen(DeviceInfo.EVENT_SCREEN_CHANGED, () => { this.forceUpdate(); });
	}

	render()
	{
		let paddingHorizontal:number = 15;
		const w = DeviceInfo.instance.width;
		if (w > 1180)
			paddingHorizontal += (w - 1180) * 0.5;

		let smallVersion:boolean = false;
		if (w <= 800)
		{
			smallVersion = true;
		}

		return (
			<View style={[styles.main, {paddingHorizontal}]}>

				<View style={smallVersion ? styles.linksWrapperSmall : styles.linksWrapper}>
					<View style={styles.linkWrapper}>
						<View style={styles.span}>
							<FontAwesomeIcon size={10} icon={faChevronRight} color='#fff' />
							<Anchor href="https://www.e-albers.de/kontakt/" target="_blank" style={styles.linkAnchor} textStyle={textStyles.contactLine}>{" " + I18n.t('links.contact')}</Anchor>
						</View>
					</View>
					<View style={[styles.linkWrapper, {marginTop: 10}]}>
						<View style={styles.span}>
							<FontAwesomeIcon size={10} icon={faChevronRight} color='#fff' />
							<Anchor href="https://www.e-albers.de/impressum/" target="_blank" style={styles.linkAnchor} textStyle={textStyles.contactLine}>{" " + I18n.t('links.imprint')}</Anchor>
						</View>
					</View>
					<View style={[styles.linkWrapper, {marginTop: 10}]}>
						<View style={styles.span}>
							<FontAwesomeIcon size={10} icon={faChevronRight} color='#fff' />
							<Anchor href="https://www.e-albers.de/datenschutzerklaerung/" target="_blank" style={styles.linkAnchor} textStyle={textStyles.contactLine}>{" " + I18n.t('links.pp')}</Anchor>
						</View>
					</View>
				</View>

				<View style={smallVersion ? styles.contactWrapperSmall : styles.contactWrapper}>
					<View>
						<View style={styles.contactWrapperPart}>
							<Text style={textStyles.contactTitle}>{I18n.t('contact.title')}</Text>
							<Text style={textStyles.contactLine}>{I18n.t('contact.address')}</Text>
						</View>

						<View style={styles.contactWrapperPart}>
							<View style={styles.span}>
								<FontAwesomeIcon size={15} icon={faPhoneSquare} color='#fff' />
								<Text style={textStyles.contactLine}>{" " + I18n.t('contact.phone')}</Text>
							</View>
							<View style={styles.span}>
								<FontAwesomeIcon size={15} icon={faFax} color='#fff' />
								<Text style={textStyles.contactLine}>{" " + I18n.t('contact.fax')}</Text>
							</View>
							<View style={styles.span}>
								<FontAwesomeIcon size={15} icon={faEnvelope} color='#fff' />
								<Anchor href={"mailto:" + I18n.t('contact.email')} target="_blank" style={styles.linkAnchor} textStyle={textStyles.contactLine}>{" " + I18n.t('contact.email')}</Anchor>
							</View>
						</View>
					</View>
				</View>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	main: {
		width: "100%",
		backgroundColor: '#444444',
		paddingVertical: 30,
		flexDirection: 'row',
		justifyContent: 'space-between',
		flexWrap: 'wrap'
	},
	linksWrapper: {
		paddingHorizontal: 15,
		width: '50%',
		minWidth: 360
	},
	linksWrapperSmall: {
		paddingHorizontal: 15,
		width: '100%',
		marginBottom: 50
	},
	linkWrapper: {
		borderBottomWidth: 1,
		borderColor: 'rgba(255,255,255, 0.3)',
		paddingBottom: 10
	},
	contactWrapper: {
		paddingHorizontal: 15,
		width: '50%',
		alignItems: 'flex-end'
	},
	contactWrapperSmall: {
		paddingHorizontal: 15,
		width: '100%',
		alignItems: 'flex-start'
	},
	contactWrapperPart: {
		marginBottom: 20
	},
	span: {
		flexDirection: 'row',
		alignItems: 'center'
	},
	linkAnchor: {
		backgroundColor: 'transparent',
		minHeight: 0,
		minWidth: 0
	}
});

const textStyles = {
	contactTitle: {
		fontSize: 15,
		color: '#fff',
		fontWeight: "700"
	},
	contactLine: {
		fontSize: 15,
		color: '#fff',
		fontWeight: "400"
	},
	link: {
		fontSize: 15,
		color: '#fff',
		fontWeight: "400"
	}
};