import { RollbarClient } from "../tracking/Rollbar";
import DeviceInfo from "../utils/DeviceInfo";

export class Logger
{
	public static create(config:any)
	{
		let { channel, enabled } = config;

		if (!channel)
			channel = "default";

		if (enabled === undefined)
			enabled = true;

		let h = function(type:string, ...args:any[])
		{
			if (!enabled)
				return;

			let str = "[" + channel + "]";

			switch (type)
			{
				case "error":
					if (RollbarClient.instance)
						RollbarClient.instance.error(args[0], args.length > 1 ? args[1] : undefined);

					if (__DEV__ || DeviceInfo.instance.web) // Don't kill the app in release
						console.error(str, ...args);
					else
						console.warn(str, ...args);
					break;

				case "warning":
					if (RollbarClient.instance)
						RollbarClient.instance.warning(args[0], args.length > 1 ? args[1] : undefined);
					console.warn(str, ...args);
					break;

				default:
					if (__DEV__)
					{
						if (RollbarClient.instance)
							RollbarClient.instance.info(args[0], args.length > 1 ? args[1] : undefined);
						console.log(str, ...args);
					}
					break;
			}
		};

		return {
			log: h.bind(null, "log"),
			warning: h.bind(null, "warning"),
			error: h.bind(null, "error")
		};
	}
}

export let { log, warning, error } = Logger.create({ channel: "default", enabled: true });

