import TrackingEvent from "./TrackingEvent";
import NetRequest from "../network/NetRequest";
import StringUtil from "../utils/StringUtil";
import MathUtil from "../utils/MathUtil";
import DeviceInfo from "../utils/DeviceInfo";
import * as Localization from 'expo-localization';

export default abstract class Matomo
{
	private static hostname:string = "";
	private static siteId:string = "";

	public static init(hostname:string, siteId:string)
	{
		Matomo.hostname = hostname;
		Matomo.siteId = siteId;
	}

	public static setHostname(hostname:string)
	{
		Matomo.hostname = hostname;
	}

	public static async sendEvent(e:TrackingEvent, eventParameters:any):Promise<void>
	{
		let visitVarsStr:string = JSON.stringify({
			"1": ["deviceType", e.deviceType],
			"2": ["deviceName", e.deviceName],
			"3": ["os", e.os],
			"4": ["osVersion", e.osVersion],
			"5": ["buildVersion", e.buildVersion]
		});

		let url:string|undefined;
		if (eventParameters && eventParameters.url)
		{
			url = eventParameters.url;
			delete eventParameters.url;
		}

		let eventVarsStr:string|undefined = undefined;
		if (eventParameters)
		{
			let idx:number = 1;
			let paramsObj = {};
			for (let k in eventParameters)
			{
				paramsObj[idx.toString()] = [k, eventParameters[k]];

				++idx;
				if (idx >= 6) // only 5 supported by matomo
					break;
			}
			eventVarsStr = JSON.stringify(paramsObj);
		}

		let now = new Date();
		let hours:number = now.getHours();
		let minutes:number = now.getMinutes();
		let seconds:number = now.getSeconds();

		const ua = DeviceInfo.instance.userAgent;

		let eventName:string = e.eventName;
		if (eventName === "pageView" && url)
		{
			if (url.startsWith("/"))
				eventName += url;
			else
				eventName += "/" + url;
			url = "https://konto.e-albers.de" + url;
		}

		if (eventName.startsWith("pageView/weblink"))
		{
			const newEventName = "pageView/weblink";
			url = eventName.substring(newEventName.length + 1);
			eventName = newEventName;
		}

		let mParams:any = {
			idsite: Matomo.siteId,
			rec: "1",
			action_name: StringUtil.replaceAll(eventName, ".", "/"),
			_id: e.clientId,
			rand: MathUtil.randomInt(1, 99999999),
			apiv: "1",
			_cvar: visitVarsStr,
			cvar: eventVarsStr,
			_idvc: e.userVisitCount,
			_viewts: e.userLastVisitDate,
			_idts: e.userFirstVisitDate,
			res: e.screenWidth + "x" + e.screenHeight,
			h: hours,
			m: minutes,
			s: seconds,
			lang: Localization.locale,
			ua
		};

		if (e.userId)
			mParams.uid = e.userId;
		if (url)
			mParams.url = url;

		await NetRequest.get(Matomo.hostname + "/piwik.php", mParams);
	}
}